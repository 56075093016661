import { HttpClient } from '@angular/common/http';
import { MedrecordTranslationLoader, TranslationLoaderConfig } from '@medrecord/abstractions-loaders';
import { EnvService } from '@medrecord/core';

export class OAuthTranslationLoader extends MedrecordTranslationLoader {}

export function createTranslateLoader(http: HttpClient, envService: EnvService): OAuthTranslationLoader {
  const config: TranslationLoaderConfig = {
    remote: {
      prefix: `${envService.backend}/public/translation/lang/`,
      suffix:  ''
    },
    
    local: {
      prefix: `./assets/i18n/`,
      suffix:  '.json'
    }
  };
  
  return new OAuthTranslationLoader(http, config);
}