import * as CustomerMetadataActions from './customer-metadata.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { CustomerGeneral, CustomerMetadata, CustomerRootOptions } from '../interfaces';

export const customerMetadataFeatureKey = 'interfaces';

export interface CustomerMetadataState extends CustomerMetadata {
  error: any;
}

export const initialState: CustomerMetadataState = {
  general: {} as CustomerGeneral,
  palettes: [],
  options: {} as CustomerRootOptions,
  error: null,
};

const customerMetadataReducer = createReducer<CustomerMetadataState>(
  initialState,
  on(CustomerMetadataActions.loadCustomerMetadata, (state) => ({
    ...state,
    error: null,
  })),
  on(CustomerMetadataActions.loadCustomerMetadataSuccess, (state, { customerMetadata }) => ({
    ...state,
    ...customerMetadata,
  })),
  on(CustomerMetadataActions.loadCustomerMetadataFailure, (state, { error }) => ({
    ...state,
    error,
  }))
);

export function reducer(state: CustomerMetadataState, action: Action): CustomerMetadataState {
  return customerMetadataReducer(state, action);
}
