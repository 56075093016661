export const findEnumKeyByValue = (enumDefinition: any, value: string): string => {
  let key;
  
  for (const enumKey in enumDefinition) {
    if (enumDefinition.hasOwnProperty(enumKey) && enumDefinition[enumKey] === value) {
      key = enumKey;
      break;
    }
  }
  
  return key;
};
