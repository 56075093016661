import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideDefaultLang } from './providers/provide-default-lang';
import { PatchValueObservableDirective } from './directives/patch-value-observable.directive';
import { NavigationUtils } from './utils/navigation.utils';
import { SafeHtmlPipe } from './pipes/sanitize/safe-html.pipe';
import { DotToCommaPipe } from './pipes/utils/dot-to-comma.pipe';
import { SafeResourceUrlPipe } from './pipes/sanitize/safe-resource-url.pipe';
import { SafeUrlPipe } from './pipes/sanitize/safe-url.pipe';
import { GetPhonePipe } from './pipes/utils/get-phone.pipe';
import { ConvertToBase64StringPipe } from './pipes/utils/convert-to-base64-string.pipe';
import { ReplacePipe } from './pipes/utils/replace.pipe';
import { MedrecordBirthdayPipe } from './pipes/utils/birthdate.pipe';
import { TranslateOrHidePipe } from './pipes/utils/translate-or-hide.pipe';
import { GetMailToPipe } from './pipes/utils/get-mail-to.pipe';
import { YearsOldPipe } from './pipes/utils/years-old.pipe';
import { EnvServiceProvider } from './providers/env.service.provider';

export const pipes = [
  MedrecordBirthdayPipe,
  YearsOldPipe,
  SafeHtmlPipe,
  SafeResourceUrlPipe,
  SafeUrlPipe,
  ConvertToBase64StringPipe,
  GetMailToPipe,
  GetPhonePipe,
  DotToCommaPipe,
  ReplacePipe,
  TranslateOrHidePipe
];

export const directives = [
  PatchValueObservableDirective
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ...pipes,
    ...directives,
  ],
  providers: [
    EnvServiceProvider,
    provideDefaultLang(),
    NavigationUtils,
  ],
  exports: [
    ...pipes,
    ...directives,
  ]
})
export class CoreModule {}
