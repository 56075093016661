import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService, MedrecordResponse } from '@medrecord/core';
import { Observable } from 'rxjs';


@Injectable()
export class LogoutService {
  constructor(
    private http: HttpClient,
    private envService: EnvService
  ) {}
  
  signOut(): Observable<MedrecordResponse> {
    return this.http.get<MedrecordResponse>(this.envService.auth + '/signout');
  }

  signOutWithSession(session: string): Observable<MedrecordResponse> {
    return this.http.post<MedrecordResponse>(this.envService.auth + '/signout/session', { session });
  }
}