export const levenshteinDistance = (a: string, b: string) => {
  if (a.length === 0) return b.length;
  if (b.length === 0) return a.length;
  const matrix = [];
  // increment along the first column of each row
  let i;
  for (i = 0; i <= b.length; i++) {
    matrix[ i ] = [ i ];
  }
  // increment each column in the first row
  let j;
  for (j = 0; j <= a.length; j++) {
    matrix[ 0 ][ j ] = j;
  }
  // Fill in the rest of the matrix
  for (i = 1; i <= b.length; i++) {
    for (j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) === a.charAt(j - 1)) {
        matrix[ i ][ j ] = matrix[ i - 1 ][ j - 1 ];
      } else {
        matrix[ i ][ j ] = Math.min(matrix[ i - 1 ][ j - 1 ] + 1, // substitution
          Math.min(matrix[ i ][ j - 1 ] + 1, // insertion
            matrix[ i - 1 ][ j ] + 1)); // deletion
      }
    }
  }
  return matrix[b.length][a.length];
};

export const getLevenshteinMatch = (word: string, vocabulary: string[]) => {
  const [ { item } ] = vocabulary
    .map((vocabularyItem) => ({
      dist: levenshteinDistance(
        vocabularyItem.toLowerCase(),
        word.toLowerCase()
      ),
      item: vocabularyItem
    }))
    .sort(
      (prev, next) => (prev.dist > next.dist ? 1 : -1)
    );
  
  return item;
};