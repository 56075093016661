import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Toast } from "@syncfusion/ej2-notifications";
import { selectErrorToasts, selectInfoToasts, selectSuccessToasts, selectWarningToasts } from '../toast.selectors';
import { ToastModel } from '@syncfusion/ej2-angular-notifications';
import { ToastAnimationSettingsModel, ToastPositionModel } from '@syncfusion/ej2-notifications/src/toast/toast-model';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'medrecord-tool-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements AfterViewInit, OnDestroy {
  @ViewChild('toast') toast: Toast;
  
  position: ToastPositionModel = { X: 'Right', Y: 'Bottom' };
  
  animation: ToastAnimationSettingsModel = {
    show: {
      effect: "FadeIn",
      duration: 600,
      easing: "linear"
    },
  
    hide: {
      effect: "FadeOut",
      duration: 600,
      easing: "linear"
    }
  };
  
  toastOptions: ToastModel = {
    position: this.position,
    newestOnTop: true,
    showProgressBar: true,
    progressDirection: 'Rtl',
    showCloseButton: true,
    animation: this.animation,
  };
  
  private unsubscribe$ = new BehaviorSubject<number>(1);
  
  constructor(
    private translateService: TranslateService,
    private store: Store
  ){}
  
  ngAfterViewInit(): void {
    this.store.select(selectInfoToasts).subscribe((toasts) => this.showToast(toasts));
    this.store.select(selectErrorToasts).subscribe((toasts) => this.showToast(toasts));
    this.store.select(selectSuccessToasts).subscribe((toasts) => this.showToast(toasts));
    this.store.select(selectWarningToasts).subscribe((toasts) => this.showToast(toasts));
  }
  
  private showToast(toasts): void {
    const toast = toasts[toasts.length - 1];
  
    if (toast && this.toast) {
      const title = toast.title && this.translateService.instant(toast.title);
      const content = toast.content && this.translateService.instant(toast.content);
      
      this.toast.show({
        ...this.toastOptions,
        ...toast,
        title,
        content: !content?.includes('error_') ? content : ''
      });
    }
  }
  
  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }
}
