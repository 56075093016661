import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule, EnvService, provideConstants, provideDefaultLang, provideStorageKey } from '@medrecord/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { createTranslateLoader } from './factories/create-translation-loader.factory';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AuthManagerModule, provideMarkOAuth, provideRedirectToAuthError } from '@medrecord/managers-auth';
import { StoreModule } from '@ngrx/store';
import { environment } from '@oauth/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MedrecordStorageModule } from '@medrecord/tools-storage';
import { MedrecordCustomerMetadataModule } from '@medrecord/services-customer-metadata';
import { MedrecordSocialLoginModule } from '@medrecord/services-social-login';
import { provideGoogleAuthConfig } from './providers/provide-google-auth-config';
import { MedrecordToastToolModule } from '@medrecord/tools-toast';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MedrecordSpinnerToolModule } from '@tools/spinner';

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    AuthManagerModule,
    BrowserModule,
    AppRoutingModule,
    MedrecordSpinnerToolModule,
    MedrecordSocialLoginModule.forRoot(),

    MedrecordStorageModule.forRoot(),
    MedrecordCustomerMetadataModule,
    MedrecordToastToolModule,

    CoreModule,

    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient, EnvService],
      },
    }),
    StoreModule.forRoot(
      {},
      {
        metaReducers: [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],
  providers: [
    provideGoogleAuthConfig(),
    // provideAuthorizationInterceptor(),

    provideMarkOAuth(),
    provideRedirectToAuthError(),

    provideDefaultLang(),
    provideStorageKey(),
    provideConstants(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
