import { StorageKey } from '@medrecord/tools-storage';
import { LOCAL_STORAGE_PREFIX } from '../constants';
import { Provider } from '@angular/core';

export function provideStorageKey(): Provider {
  return (
    {
      provide: StorageKey,
      useValue: LOCAL_STORAGE_PREFIX,
    }
  );
}