import { getScrollParent } from './get-first-scroll-parent.util';

export function scrollToFirstInvalidField(): void {
  const ngInvalidClassName = '.ng-invalid';
  const exclude = ':not(app-common-form-wrapper):not(form)';
  
  const target: HTMLElement = document.querySelector(ngInvalidClassName + exclude);
  
  getScrollParent(target).scrollTo({
    top: target.offsetTop > 40 ? target.offsetTop - 40 : target.offsetTop,
    behavior: 'smooth'
  });
}