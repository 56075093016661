import { Directive, Input } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

/**
 * Patch reactive form values from Observables
 * @controlName formControlName in the form
 * @doctorGuiPatchValueObservable latest emitted value from Observable
 */
@Directive({ selector: '[doctorGuiPatchValueObservable]' })
export class PatchValueObservableDirective {
  @Input('controlName') formControlName: string;
  @Input('doctorGuiPatchValueObservable')
  set data(val: any) {
    if (val && this.formGroupDirective.form) {
      this.formGroupDirective.form.patchValue({[this.formControlName]: val });
      this.formGroupDirective.form.markAsPristine();
    }
  }
  constructor(private formGroupDirective: FormGroupDirective) {}
}