import { createAction, props, } from '@ngrx/store';
import { RestorePasswordPayload } from '../../models/interfaces';
import { WithSuccessActions } from '@medrecord/core';

/** Set Password */
export const setPasswordAction = createAction(
  '[Auth Manager] Set Password',
  props<WithSuccessActions<RestorePasswordPayload>>()
);

export const acceptInvitationSuccessAction = createAction(
  '[Auth Manager] Accept Invitation Success'
);

export const acceptInvitationFailureAction = createAction(
  '[Auth Manager] Accept Invitation Failure',
  props<{ error: any }>()
);
