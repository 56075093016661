import { APP_INITIALIZER, Provider } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthRouteParams } from '../models/enums/auth-route-params.enum';
import { requestTokensAction } from '../state-management/auth-manager.actions';
import { selectRefreshToken } from '../state-management/auth-manager.selectors';
import { filter } from 'rxjs/operators';

export function provideTokenInitializationFactory(store: Store): Function {
  return () => new Promise((resolve) => {
    const code = (new URL(location.href)).searchParams.get(AuthRouteParams.Code);
  
    if (code) {
      localStorage.removeItem('idle-time');
      store.dispatch(requestTokensAction({ code }));
    
      store.select(selectRefreshToken).pipe(
        filter((token) => !!token)
      ).subscribe(() => {
        resolve();
      });
    
      return;
    }
  
    resolve();
  });
}

export function provideTokenInitialization(): Provider[] {
  return [
    {
      provide: APP_INITIALIZER,
      useFactory: provideTokenInitializationFactory,
      deps: [Store],
      multi: true
    }
  ];
}