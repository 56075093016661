import { getDataUriMimeType } from './get-data-uri-mime-type.util';

export const mapDataUriToImageModel = (dataUri: string): { photoBase64Data: string, photoContentType: string; } => (
  dataUri && (typeof dataUri === 'string')
    ? {
      photoBase64Data: dataUri.split(',')[1],
      photoContentType: getDataUriMimeType(dataUri)
    }
    : {
      photoBase64Data: "",
      photoContentType: ""
    }
);