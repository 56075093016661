import { APP_INITIALIZER, Provider } from '@angular/core';
import { Router } from '@angular/router';
import { AuthRouteParams } from '../models/enums/auth-route-params.enum';
import { authRouteNames } from '@medrecord/routes-auth';

export function provideRedirectToAuthErrorFactory(router: Router): Function {
  return () => {
    const params = new URL(document.location.href).searchParams;
    
    const redirectURI = params.get(AuthRouteParams.RedirectUri);
    const clientId = params.get(AuthRouteParams.ClientId);
    const responseType = params.get(AuthRouteParams.ResponseType);
    
    if (!responseType || !clientId || !redirectURI) {
      router.navigateByUrl(`/${authRouteNames.Error}`);
    }
  };
}

export function provideRedirectToAuthError(): Provider[] {
  return [
    {
      provide: APP_INITIALIZER,
      useFactory: provideRedirectToAuthErrorFactory,
      deps: [Router],
      multi: true
    }
  ];
}