import { APP_INITIALIZER, NgModule } from '@angular/core';
import { createMedrecordDateTimeFactory } from './medrecord-datetime.factory';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MedrecordDatetimeFormatPipe } from './medrecord-datetime-format.pipe';
import { PrecisionFormatPipe } from './precision-format.pipe';

export const pipes = [
  MedrecordDatetimeFormatPipe,
  PrecisionFormatPipe
];

@NgModule({
  imports: [
    TranslateModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (createMedrecordDateTimeFactory),
      deps: [TranslateService],
      multi: true,
    }
  ],
  declarations: [
    ...pipes
  ],
  exports: [
    ...pipes
  ]
})
export class MedrecordDatetimeModule {
}
