import { FormGroup } from '@angular/forms';

export function markFormGroupTouched(formGroup: FormGroup): void {
  Object.values(formGroup.controls).forEach((control: any) => {
    control.markAsTouched();

    if (control.controls) {
      markFormGroupTouched(control);
    }
  });
}

export function scrollToFirstInvalid(): void {
  const ngInvalidClassName = '.ng-invalid';
  const exclude = ':not(app-common-form-wrapper):not(form)';

  const target: any = document.querySelector(ngInvalidClassName + exclude);

  window.scrollTo({
    top: target.offsetTop,
    behavior: 'smooth',
  });
}

