import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StorageService } from './services/storage.service';
import { Storage, StorageKey } from './storage.injection-token';

@NgModule({
  imports: [CommonModule],
  providers: [
    StorageService,
    {
      provide: StorageKey,
      useValue: '',
    },
    {
      provide: Storage,
      useValue: localStorage,
    },
  ],
})
export class MedrecordStorageModule {
  static forRoot(): ModuleWithProviders<MedrecordStorageModule> {
    return {
      ngModule: MedrecordStorageModule,
      providers: [StorageService],
    };
  }
}
