import { Action, createReducer, on } from '@ngrx/store';
import { ToastModel } from '@syncfusion/ej2-angular-notifications';
import { addErrorToast, addInfoToast, addSuccessToast, addWarningToast } from './toast.actions';

export const featureToastKey = 'toast-tool';

export interface ToastState {
  info: ToastModel[];
  error: ToastModel[];
  success: ToastModel[];
  warning: ToastModel[];
}

export const initialState = {
  info: [],
  error: [],
  success: [],
  warning: [],
};

export const toastsReducer = createReducer(
  initialState,
  on(
    addErrorToast,
      (state, { content, title }) => ({
      ...state,
      error: [
        ...state.error,
        {
          content,
          icon: 'fas fa-exclamation-circle',
          title: title || 'Some error',
          cssClass: 'error-toast'
        }
      ]
    })
  ),
  on(
    addSuccessToast,
    (state, { content, title }) => ({
      ...state,
      success: [
        ...state.success,
        {
          content,
          icon: 'fas fa-check-circle',
          title: title || 'Success',
          cssClass: 'success-toast'
        }
      ]
    })
  ),
  on(
    addWarningToast,
    (state, { content, title }) => ({
      ...state,
      warning: [
        ...state.warning,
        {
          content,
          icon: 'fas fa-exclamation-triangle',
          title: title || 'Some warning',
          cssClass: 'warning-toast'
        }
      ]
    })
  ),
  on(
    addInfoToast,
    (state, { content, title }) => ({
      ...state,
      info: [
        ...state.info,
        {
          content,
          icon: 'fas fa-info-circle',
          title: title || 'Info',
          cssClass: 'info-toast'
        }
      ]
    })
  )
);

export function reducer(state: ToastState, action: Action): ToastState {
  return toastsReducer(state, action);
}
