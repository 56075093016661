import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { EnvService, Language, StorageKeys } from '@medrecord/core';
import { getOAuthConfigUtil } from '@medrecord/tools-utils';
import { AuthRouteParams, OAuthChallengeMethod, OAuthResponseType } from '../models/enums';
import { StorageService } from '@medrecord/tools-storage';

@Injectable()
export class MedrecordOAuthService {
  private medrecordOAuthReferenceObserver = new Subject();
  private medrecordOAuthReference: Window;
  
  constructor(
    private storage: StorageService<StorageKeys>,
    private envService: EnvService
  ) {}
  
  loginWithMedrecord(codeVerifier: string, externalUrl?: URL): Observable<any> {
    if (externalUrl) {
      this.manageOAuthReference(externalUrl.toString());
    } else {
      this.getUrl(codeVerifier).then((url) => {
        this.manageOAuthReference(url);
      });
    }
    
    return this.medrecordOAuthReferenceObserver.asObservable();
  }
  
  private manageOAuthReference(url: string): void {
    this.medrecordOAuthReference = window.open(url, 'OAuth', 'height=600,width=600');
    this.checkOAuthClosing();
  }
  
  private checkOAuthClosing(): void {
   const interval = setInterval(() => {
     if (this.medrecordOAuthReference.closed) {
       this.medrecordOAuthReferenceObserver.error({ error: 'popup_closed_by_user' });
       clearInterval(interval);
     }
    }, 1000);
  }
  
  private async getUrl(codeVerifier: string): Promise<string> {
    const oAuthConfig = await getOAuthConfigUtil(codeVerifier);
    const url = new URL(this.envService.oauth);
  
    url.searchParams.set(AuthRouteParams.RedirectUri, oAuthConfig.redirectUri);
    url.searchParams.set(AuthRouteParams.ClientId, this.envService.clientId);
    url.searchParams.set(AuthRouteParams.CodeChallenge, oAuthConfig.codeChallenge);
    url.searchParams.set(AuthRouteParams.CodeChallengeMethod, OAuthChallengeMethod.S256);
    url.searchParams.set(AuthRouteParams.ResponseType, OAuthResponseType.CODE);
    url.searchParams.set(AuthRouteParams.State, oAuthConfig.state);
    url.searchParams.set(AuthRouteParams.Language, this.storage.getItem<Language>(StorageKeys.Language));
    
    return url.toString();
  }
}