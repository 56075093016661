import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getPhone'
})
export class GetPhonePipe implements PipeTransform {
  phoneRegex: RegExp = /^[+]*[(]{0,1}[-\d]{1,5}[)]{0,1}[-\s\d]+[\d]$/;
  
  transform(value: string): string {
    return this.phoneRegex.test(value) ? 'tel:' + value : value;
  }

}
