import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import {
  AuthManagerState,
  authMangerSyncConfig,
  createAuthMangerReducer,
  featureAuthMangerKey
} from './auth-manager.reducers';
import { FEATURE_STORE_CONFIG, NgrxSyncStorageModule } from '@medrecord/tools-ngrx-sync-storage';
import { EffectsModule } from '@ngrx/effects';
import { LogoutEffects } from './effects/logout.effects';
import { PasswordEffects } from './effects/password.effects';
import { InvitationEffects } from './effects/invitation.effects';
import { RedirectEffects } from './effects/redirect.effects';
import { SignInEffects } from './effects/signin.effects';
import { TokenEffects } from './effects/token.effects';
import { SignUpEffects } from './effects/signup.effects';
import { PhoneVerificationEffects } from './effects/phone-verification.effects';
import { RouterModule } from '@angular/router';
import { TwofaEffects } from './effects/twofa.effects';
import { twofaFeatureKey, twofaReducer } from './twofa.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature<AuthManagerState, any>(featureAuthMangerKey, createAuthMangerReducer, FEATURE_STORE_CONFIG),
    StoreModule.forFeature(twofaFeatureKey, twofaReducer),

    RouterModule,
    NgrxSyncStorageModule.forFeature(authMangerSyncConfig),
  
    EffectsModule.forFeature([
      LogoutEffects,
      PasswordEffects,
      InvitationEffects,
      RedirectEffects,
      SignInEffects,
      SignUpEffects,
      TokenEffects,
      PhoneVerificationEffects,
      TwofaEffects
    ]),
  ],
  exports: [
    RouterModule
  ]
})
export class AuthManagerStateModule {

}