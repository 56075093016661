import * as BaseMoment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import { Language } from './typings';

const MedrecordMoment = extendMoment(BaseMoment);

const MomentLocaleConfigs = {
  [Language.EN]: {
    longDateFormat: {
      LT: 'DD MMM YYYY HH:mm',
      LTS: 'DD MMM YYYY HH:mm:ss',
      L: 'DD/MM/YYYY',
      LL: 'DD MMM YYYY',
      LLL: 'DD MMM YYYY  HH:mm',
      LLLL: 'dddd, D MMM YYYY  HH:mm'
    },
    week: {
      dow: 1, // set start of week to monday instead
      doy: 0,
    },
  },
  [Language.NL]: {
    longDateFormat: {
      LT: 'DD MMM YYYY HH:mm',
      LTS: 'DD MMM YYYY HH:mm:ss',
      L: 'DD/MM/YYYY',
      LL: 'D MMM YYYY',
      LLL: 'D MMM YYYY  HH:mm',
      LLLL: 'dddd, D MMM YYYY  HH:mm'
    },
  }
};

export {
  MedrecordMoment,
  MomentLocaleConfigs,
  BaseMoment,
};

