import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { generalRouteNames, provideGeneralRouteNames } from '@medrecord/routes-general';
import { provideAuthRouteNames } from '@medrecord/routes-auth';

export const OAUTH_ROUTES: Routes = [
  {
    path: generalRouteNames.Auth.Entry,
    loadChildren: () => import('@medrecord/oauth-view').then(m => m.OauthViewModule)
  },
  {
    path:  generalRouteNames.Any,
    redirectTo: generalRouteNames.Auth.Entry,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(OAUTH_ROUTES, { initialNavigation: 'enabled' })
  ],
  providers: [
    provideGeneralRouteNames(),
    provideAuthRouteNames(generalRouteNames.Auth.Entry),
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {}