import { APP_INITIALIZER, Provider } from '@angular/core';
import { Store } from '@ngrx/store';
import { ACCESS_TOKEN_RULES, AccessTokenRules } from '../constants';
import { logout } from '../state-management/auth-manager.actions';
import { MedrecordMoment } from '@medrecord/services-datetime';

export function provideLogoutOnIdleFactory(tokenRules: AccessTokenRules, store: Store): Function {
  const idleEvents = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
  let idleTimer;
  
  const resetTimer = () => {
    clearTimeout(idleTimer);
    
    localStorage.setItem(
      'idle-time',
      MedrecordMoment().add(tokenRules.idleLogoutTimeout, 'millisecond').toISOString()
    );
    
    idleTimer = setTimeout(() => {
      store.dispatch(logout());
    }, tokenRules.idleLogoutTimeout);  // time is in milliseconds
  };
  
  return () => {
    const endDate = localStorage.getItem('idle-time');
    
    if (!endDate) {
      localStorage.setItem(
        'idle-time',
        MedrecordMoment().add(tokenRules.idleLogoutTimeout, 'millisecond').toISOString()
      );
    }
    
    if (endDate && MedrecordMoment().isAfter(MedrecordMoment(endDate))) {
      localStorage.removeItem('idle-time');
      store.dispatch(logout());
      return;
    }
    
    window.addEventListener('load', resetTimer, { passive: true });
    
    idleEvents.forEach((name) => {
      document.addEventListener(name, resetTimer, { passive: true });
    });
  };
}

export function provideLogoutOnIdle(): Provider[] {
  return [
    {
      provide: APP_INITIALIZER,
      useFactory: provideLogoutOnIdleFactory,
      deps: [ACCESS_TOKEN_RULES, Store],
      multi: true
    }
  ];
}