/**
 * Token lifetime is fixed. But there's a time gap between token creation
 * and receiving it at FE.
 * This const is created to take it into account
 *
 * Measured in seconds
 */
export const accessTokenDelay = 30;

/**
 * Access token lifetime.
 *
 * Measured in seconds
 */
export const accessTokenLifeTime = 900;

/**
 * Access token lifetime after it came to the frontend.
 *
 * Measured in milliseconds
 */
export const accessTokenExpirationPeriod = (accessTokenLifeTime - accessTokenDelay) * 1000;

/**
 * Timeout after which the app will automatically logout
 *
 * Measured in miliseconds
 */
export const idleLogoutTimeout = 15 * 60 * 1000; // 15 minutes

/**
 * Timeout gap before token expired to have enough time to refresh the token
 *
 * Measured in milliseconds
 */
export const timeGapBeforeTokenExpired = 3 * 60 * 1000; // 3 minutes