import { LANGUAGES_COLLECTION, SEARCH } from '../coaching-core.tokens';
import { Language } from '../models/enums';
import * as searchConstants from '../constants/search.constant';
import { Provider } from '@angular/core';

export function provideConstants(): Provider[] {
  return [
    {
      provide: LANGUAGES_COLLECTION,
      useValue: {
        [Language.EN]: {
          label: 'common_language_english',
          value: Language.EN,
        },

        [Language.NL]: {
          label: 'common_language_dutch',
          value: Language.NL,
        },
        [Language.DE]: {
          label: 'common_language_german',
          value: Language.DE,
        },
        [Language.ES]: {
          label: 'common_language_spanish',
          value: Language.ES,
        },
        [Language.TR]: {
          label: 'common_language_turkish',
          value: Language.TR,
        },
      },
    },
    {
      provide: SEARCH,
      useValue: searchConstants,
    },
  ];
}
