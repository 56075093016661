import { Injectable } from '@angular/core';
import { GoogleLoginProvider, SocialAuthService as AngularXSocialAuthService } from 'angularx-social-login';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocialAuthService {
  constructor(private socialAuth: AngularXSocialAuthService) {
  }
  
  signInWithGoogle(): Observable<string> {
    return from(
      this.socialAuth
        .signIn(GoogleLoginProvider.PROVIDER_ID)
        .then(({ idToken }) => idToken)
    );
  }
  
}
