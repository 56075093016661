import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translateOrHide'
})
export class TranslateOrHidePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}
  
  transform(key: string, options?: { [key: string]: string }): string {
    const translation = this.translateService.instant(key, options);
    return translation !== key ? translation : '';
  }
}
