import jwt from 'jsrsasign';

export const generateJWT = ({
  alg,
  id,
  payload,
  secret,
}: {
  alg: string;
  id: string;
  payload: any;
  secret: string;
}): string => {
  const token = jwt.KJUR.jws.JWS.sign(
    alg,
    JSON.stringify({ alg: alg, kid: id, typ: 'JWT' }),
    JSON.stringify(payload),
    secret
  );
  return token;
};
