import { InjectionToken } from '@angular/core';

export const generalRouteNames = {
  Entry: '',
  Base: '',
  Any: '**',

  Auth: {
    Entry: 'auth',
  },
  Atoms: {
    Entry: 'atoms',
  },
  Website: {
    Entry: 'public',
  },
  Editor: {
    Entry: 'editor',
  },
  Medsafe: {
    Entry: 'patient',
  },
  Admin: {
    Entry: 'admin',
  },
  Doctor: {
    Entry: 'doctor',
    Patient: 'doctor/network/:userId',
  },
  Patient: {
    Entry: 'patient',
    LegacyMedmijAuthBack: 'external-services/healthcare-providers/auth-back',
    MedmijAuthBack: 'my-doctor/auth-back',
  },
  Onboarding: {
    Entry: 'onboarding',
    Invitation: 'invitation',
  },
  Profile: {
    Entry: 'profile',
    Edit: 'edit',
  },
  News: {
    Entry: 'news',
  },
  Chat: {
    Entry: 'chat',
  },
  Settings: {
    Entry: 'settings',
  },
  Notifications: {
    Entry: 'notifications',
  },
  ConfirmMobile: {
    Entry: 'confirm-mobile',
  },
  PaymentStatus: {
    Entry: 'payment-status',
  },
  PaymentPlans: {
    Entry: 'payment-plans',
  },
  HealthTalk: {
    Entry: '',
  },

  Redirect: 'redirect',
};

export type GeneralRouteNames = typeof generalRouteNames;

export const GENERAL_ROUTE_NAMES = new InjectionToken<GeneralRouteNames>('General App Route Names');

export const provideGeneralRouteNames = (entry = '') => [
  {
    provide: GENERAL_ROUTE_NAMES,
    useValue: { ...generalRouteNames, Entry: entry },
  },
];
