const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/i;

export const convertToBase64DataUri = (photoBase64Data: string, photoContentType: any = 'image/png') => {
  if (!photoBase64Data) {
    return undefined;
  }

  if (urlRegex.test(photoBase64Data)) {
    return photoBase64Data;
  }

  return 'data:' + photoContentType + ';base64,' + photoBase64Data;
};

export const getBase64MimeType = (encoded: string): null | string => {
  let result = null;

  if (typeof encoded !== 'string') {
    return result;
  }

  const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

  if (mime && mime.length) {
    result = mime[1];
  }

  return result;
};

export function parseBrowserEncodedBase64(encoded: string): { base64String: string; mimeType: string } {
  if (typeof encoded !== 'string') {
    return null;
  }

  return {
    base64String: encoded.split(',')[1],
    mimeType: getBase64MimeType(encoded),
  };
}
