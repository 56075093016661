import { createAction, props } from '@ngrx/store';
import { MedrecordResponse } from '@medrecord/core';
import { VerificationPhoneSavePayload } from '../../models/interfaces';

export const getPhoneCode = createAction(
  '[Auth Manager] Get Phone Verification Code'
);

export const getPhoneCodeSuccess = createAction(
  '[Auth Manager] Get Phone Verification Code Success',
  props<MedrecordResponse>()
);

export const getPhoneCodeFailure = createAction(
  '[Auth Manager] Get Phone Verification Code Failure',
  props<{ error: any }>()
);

export const checkPhoneCode = createAction(
  '[Auth Manager] Check Phone Verification Code',
  props<{ code: string }>()
);

export const checkPhoneCodeSuccess = createAction(
  '[Auth Manager] Check Phone Verification Code Success',
  props<MedrecordResponse>()
);

export const checkPhoneCodeFailure = createAction(
  '[Auth Manager] Check Phone Verification Code Failure',
  props<{ error: any }>()
);

export const initPhone = createAction(
  '[Auth Manager] Init Phone',
  props<{ phone: string }>()
);

export const initPhoneSuccess = createAction(
  '[Auth Manager] Init Phone Success',
  props<MedrecordResponse>()
);

export const initPhoneFailure = createAction(
  '[Auth Manager] Init Phone Failure',
  props<{ error: any }>()
);

export const savePhone = createAction(
  '[Auth Manager] Save Phone',
  props<{ code: string }>()
);

export const savePhoneSuccess = createAction(
  '[Auth Manager] Save Phone Success',
  props<MedrecordResponse>()
);

export const savePhoneFailure = createAction(
  '[Auth Manager] Save Phone Failure',
  props<{ error: any }>()
);

export const initPhoneChange = createAction(
  '[Auth Manager] Init Phone Change',
  props<{ phone: string }>()
);

export const initPhoneChangeSuccess = createAction(
  '[Auth Manager] Init Phone Change Success',
  props<MedrecordResponse>()
);

export const initPhoneChangeFailure = createAction(
  '[Auth Manager] Init Phone Change Failure',
  props<{ error: any }>()
);

export const phoneChangeSave = createAction(
  '[Auth Manager] Phone Change Save',
  props<VerificationPhoneSavePayload>()
);

export const phoneChangeSaveSuccess = createAction(
  '[Auth Manager] Phone Change Save Success',
  props<MedrecordResponse>()
);

export const phoneChangeSaveFailure = createAction(
  '[Auth Manager] Phone Change Save Failure',
  props<{ error: any }>()
);