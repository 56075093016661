import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { combineLatest, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { TranslationLoaderConfig } from './translation-loader-config.interface';

export abstract class MedrecordTranslationLoader {
  remoteLoader: TranslateHttpLoader;
  localLoader: TranslateHttpLoader;
  localOverrides: boolean;
  
  constructor(client: HttpClient, config: TranslationLoaderConfig, localOverrides: boolean = false) {
    this.localLoader = new TranslateHttpLoader(client, config.local.prefix, config.local.suffix);
    this.remoteLoader = new TranslateHttpLoader(client, config.remote.prefix, config.remote.suffix);
    this.localOverrides = localOverrides;
  }
  
  getTranslation(language: string): Observable<any> {
    return combineLatest([
      this.localLoader.getTranslation(language),
      this.remoteLoader.getTranslation(language)
    ])
      .pipe(
        map(([local, remote]) => {
          if (this.localOverrides) {
            return {
              ...Object.keys(remote).reduce((acc, key) => ({ ...acc, ...remote[key] }), {}),
              ...local,
            };
          }
          return {
            ...local,
            ...Object.keys(remote).reduce((acc, key) => ({ ...acc, ...remote[key] }), {})
          };
        })
      );
  }
}