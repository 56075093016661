import { AfterViewInit, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { getSpinnerCounter } from '../state-management/spinner.selectors';
import { createSpinner, hideSpinner, showSpinner } from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'medrecord-tool-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements AfterViewInit {
  timer = null;
  showSpinner = false;
  minimumSpinnerShowTimeSeconds = 0.4;
  
  constructor(
    private store: Store
  ) {
    createSpinner({
      target: document.querySelector('body'),
      template: '<div style="width:100%;height:100%" class="custom-rolling"><div></div></div>'
    });
    
    showSpinner(document.querySelector('body'));
  }
  
  ngAfterViewInit(): void {
    hideSpinner(document.querySelector('body'));
    this.observeSpinnerActivation();
  }
  
  private observeSpinnerActivation(): void {
    this.store.select(getSpinnerCounter)
      .subscribe((state) => {
        clearTimeout(this.timer);
        this.timer = null;

        if (state?.counter) {
          this.showSpinner = true;
        } else {
          /** Use timer for avoiding flashing of spinner between requests breaks */
          this.timer = setTimeout(() => {
            this.showSpinner = false;
          }, this.minimumSpinnerShowTimeSeconds * 1000);
        }
      });
  }
}
