import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class NavigationUtils {
  constructor(
    private router: Router
  ) {}
  
  getRootPath(items: string[]): string[][] {
    const urlChunks = this.router.url.split('/');
  
    let idIndex = -1;
  
    items.forEach((id) => {
      const index = urlChunks.indexOf(id);
    
      idIndex = idIndex < index ? index : idIndex;
    });
    
    return [urlChunks.slice(0, idIndex), urlChunks.slice(idIndex)];
  }
}