import { InjectionToken } from '@angular/core';

export const authRouteNames = {
  Entry: '',
  Base: '',
  Error: 'authorization-error',

  Auth: {
    Entry: '',
    Login: 'login',
    SignUp: 'signup',
    Role: 'role',
    Credentials: 'credentials',
    FinishedSignUp: 'signup-finished',
  },
  Twofa: {
    Entry: 'twofa',
    Setup: 'setup',
    Verification: 'verification',
    Reconfiguration: 'reconfiguration',
    Recovery: 'recovery',
  },

  Login: {
    Entry: 'login',
  },

  Verification: {
    Entry: 'verification',
    Phone: 'phone',
    Init: 'init',
    Code: 'code',
    Save: 'save',
  },

  Register: {
    Entry: 'register',
    Confirm: 'confirm',
    ConfirmMobile: 'confirm-mobile',
    AdminInvite: 'confirm-invitation',
  },

  SignUp: {
    Entry: 'signup',
    Role: 'role',
    Credentials: 'credentials',
    Finished: 'signup-finished',
  },

  Password: {
    Entry: '',
    Forgot: 'forgot-password',
    Restore: 'restore',
    Success: 'success',
    SuccessMobile: 'success-mobile',
    RestoreEmailSent: 'restore-password-email-sent',
  },

  Invitation: {
    Entry: 'invitation',
    Accepted: 'invitation-accepted',
  },
};

export type AuthRouteNames = typeof authRouteNames;

export const AUTH_ROUTE_NAMES: InjectionToken<AuthRouteNames> = new InjectionToken<AuthRouteNames>(
  'Route Names connected by Auth library'
);

export const provideAuthRouteNames = (entry = '') => [
  {
    provide: AUTH_ROUTE_NAMES,
    useValue: { ...authRouteNames, Entry: entry },
  },
];
