import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getMailTo'
})
export class GetMailToPipe implements PipeTransform {
  emailRegex: RegExp = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  
  transform(value: string): string {
    return this.emailRegex.test(value) ? 'mailto:' + value : value;
  }

}
