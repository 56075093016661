import {
  cancelTwofaFlowAction,
  fetchTwofaQrCodeAction,
  handleTwofaNextStepAction,
  redirectToSettingsAction,
  setTwofaCodesToRememberAction,
  setTwofaQrCodeAction,
  setTwofaStepAction,
  submitTwofaCodeAction,
  twofaFailureAction,
  twofaVerificationSuccess
} from '../actions/twofa.actions';
import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { SignInService } from '../../services/signin.service';
import { getTwofaFlow, getTwofaStep } from '../twofa.selectors';
import { Store } from '@ngrx/store';
import { selectSession } from '../auth-manager.selectors';
import { addErrorToast } from '@medrecord/tools-toast';
import { TwofaFlow } from '../../models/enums';
import { Observable } from 'rxjs';
import { RecoveryCodeResponse } from '../../models/interfaces/twofa/recovery-code-response.interface';
import { logout } from '../actions/logout.actions';
import { Router } from '@angular/router';
import { GENERAL_ROUTE_NAMES, GeneralRouteNames } from '@medrecord/routes-general';
import { getErrorToastBodyUtil } from '@medrecord/tools-utils';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class TwofaEffects {
  cancelTwofaFlow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancelTwofaFlowAction),
      withLatestFrom(this.store.select(getTwofaFlow)),
      switchMap(([, flow]) => {
        if (flow === TwofaFlow.Reconfiguration) {
          return [redirectToSettingsAction()];
        }

        return [logout()];
      })
    )
  );

  handleTwofaNextStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(handleTwofaNextStepAction),
      withLatestFrom(
        this.store.select(getTwofaStep),
        this.store.select(getTwofaFlow)
      ),
      switchMap(([, step, flow]) => {
        if (step === 5) {
          return [flow === TwofaFlow.Reconfiguration
            ? redirectToSettingsAction()
            : twofaVerificationSuccess()];
        }
        return [setTwofaStepAction({ step: step + 1 })];
      })
    )
  );

  fetchTwofaQrCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchTwofaQrCodeAction),
      withLatestFrom(
        this.store.select(selectSession),
        this.store.select(getTwofaFlow)
      ),
      switchMap(([, session, flow]) => {
        const fetchMethod = flow === TwofaFlow.Setup
          ? this.signInService.getTwofaQrCode(session)
          : this.signInService.reconfigTwofaInit();
        return fetchMethod.pipe(
          map((totpSecret) => setTwofaQrCodeAction({ qrCodeUri: totpSecret.otpUri })),
          catchError((error) => [twofaFailureAction({ error })])
        );
      })
    )
  );

  submitTwofaCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitTwofaCodeAction),
      withLatestFrom(
        this.store.select(selectSession),
        this.store.select(getTwofaFlow)
      ),
      switchMap(([{ code }, session, flow]) => {
        let sendCodeMethod: Observable<RecoveryCodeResponse | void>;
        const callbackActions = [];
        switch (flow) {
          case TwofaFlow.Setup:
            sendCodeMethod = this.signInService.sendTwofaSetupCode(session, code);
            callbackActions.push((response: RecoveryCodeResponse) => setTwofaCodesToRememberAction({ codesToRemember: response.codes }));
            callbackActions.push(() => handleTwofaNextStepAction());
            break;
          case TwofaFlow.Reconfiguration:
            sendCodeMethod = this.signInService.reconfigTwofaInitSave(code);
            callbackActions.push((response: RecoveryCodeResponse) => setTwofaCodesToRememberAction({ codesToRemember: response.codes }));
            callbackActions.push(() => handleTwofaNextStepAction());
            break;
          case TwofaFlow.Verification:
            sendCodeMethod = this.signInService.checkTwofaCode(session, code);
            callbackActions.push(() => twofaVerificationSuccess());
            break;
          case TwofaFlow.Recovery:
            sendCodeMethod = this.signInService.recoveryTwofaCode(session, code);
            callbackActions.push(() => twofaVerificationSuccess());
            break;
        }
        return sendCodeMethod.pipe(
          switchMap(response => callbackActions.map(callback => callback(response))),
          catchError((error) => [twofaFailureAction({ error })])
        );
      })
    )
  );


  twofaFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(twofaFailureAction),
      switchMap(({ error }) =>
        [addErrorToast(
          getErrorToastBodyUtil(this.translateService.instant('tfa_code_verification_error'), error)
        )]
      )
    )
  );


  redirectToSettings$ = createEffect(() =>
      this.actions$.pipe(
        ofType(redirectToSettingsAction),
        map(() => this.router.navigate([this.generalRouteNames.Settings.Entry]))
      ),
    { dispatch: false }
  );


  constructor(
    private store: Store,
    private actions$: Actions,
    private signInService: SignInService,
    private router: Router,
    private translateService: TranslateService,
    @Inject(GENERAL_ROUTE_NAMES) private generalRouteNames: GeneralRouteNames
  ) {
  }
}
