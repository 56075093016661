import { createSelector } from '@ngrx/store';
import { State, twofaFeatureKey } from './twofa.reducer';
import { TwofaFlow } from '../models/enums';

const getState = (state: State) => state[twofaFeatureKey];

export const getTwofaFlow = createSelector(
  getState,
  (state: State): TwofaFlow => state.flow
);

export const getTwofaStep = createSelector(
  getState,
  (state: State): number => state.step
);

export const getTwofaQrCodeUri = createSelector(
  getState,
  (state: State): string => state.qrCodeUri
);

export const getTwofaCodesToRemember = createSelector(
  getState,
  (state: State): string[] => state.codesToRemember
);
