import { createFeatureSelector, createSelector } from '@ngrx/store';
import { customerMetadataFeatureKey, CustomerMetadataState } from './customer-metadata.reducer';

export const getCustomerMetadataState = createFeatureSelector<CustomerMetadataState>(customerMetadataFeatureKey);

export const getCustomerPalettes = createSelector(
  getCustomerMetadataState,
  (state: CustomerMetadataState) => state.palettes
);

export const getCustomerGeneral = createSelector(
  getCustomerMetadataState,
  (state: CustomerMetadataState) => state.general
);

export const getCustomerMetadata = createSelector(getCustomerMetadataState, (state: CustomerMetadataState) => state);

export const getCustomerLogo = createSelector(
  getCustomerMetadataState,
  (state: CustomerMetadataState) => state.general?.logo
);

export const getCustomerTopMenuLogo = createSelector(
  getCustomerMetadataState,
  (state: CustomerMetadataState) => state.general?.options.topMenuLogo
);

export const getCustomerInnerLogo = createSelector(
  getCustomerMetadataState,
  (state: CustomerMetadataState) => state.general?.logoInner
);

export const getCustomerLogoMinimal = createSelector(
  getCustomerMetadataState,
  (state: CustomerMetadataState) => state.general?.logoMinimal
);

export const getCustomerShortName = createSelector(
  getCustomerMetadataState,
  (state: CustomerMetadataState) => (window as any).SHORT_NAME || state.general?.shortName
);
