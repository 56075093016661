import * as formRules from './form-rules.const';
import * as accessTokenRules from './access-token-rules.const';
import { InjectionToken, Provider } from '@angular/core';

export const FORM_RULES = new InjectionToken('Constant Rules for authorization forms');
export const ACCESS_TOKEN_RULES = new InjectionToken('Constant Rules for access token');
export const COUNTRIES_LIST = new InjectionToken("Countries List");

export type FormRules = typeof formRules;
export type AccessTokenRules = typeof accessTokenRules;
export interface CountryItem { value: string; text: string; }

export const authManagerConstants = {
  formRules,
  accessTokenRules
};

export const AuthManagerConstantsProviders = {
  provideFormRules(newRules?: FormRules): Provider[] {
    return [
      {
        provide: FORM_RULES,
        useValue: newRules || formRules
      }
    ];
  },
  
  provideAccessTokenRules(newRules?: AccessTokenRules): Provider[] {
    return [
      {
        provide: ACCESS_TOKEN_RULES,
        useValue: newRules || accessTokenRules
      }
    ];
  }
};