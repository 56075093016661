import { APP_INITIALIZER, NgModule } from '@angular/core';
import { Store, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromCustomerMetadata from './state-management/customer-metadata.reducer';
import { CustomerMetadataEffects } from './state-management/customer-metadata.effects';
import { loadCustomerMetadata } from './state-management/customer-metadata.actions';
import { CustomerMetadataService } from './customer-metadata.service';
import { CustomerMetadata } from './interfaces';
import { ColorsModule } from '@medrecord/services-colors';

export function loadCustomerMetadataFactory(store: Store<CustomerMetadata>): Function {
  function loader(): Promise<void> {
    return new Promise((resolve) => {
      store.dispatch(loadCustomerMetadata());
    
      resolve();
    });
  }
  
  return loader;
}

@NgModule({
  imports: [
    StoreModule.forFeature(fromCustomerMetadata.customerMetadataFeatureKey, fromCustomerMetadata.reducer),
    EffectsModule.forFeature([CustomerMetadataEffects]),
    ColorsModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: loadCustomerMetadataFactory,
      deps: [Store],
      multi: true,
    },
    CustomerMetadataService,
  ],
})
export class MedrecordCustomerMetadataModule {
}
