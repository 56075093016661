import { createAction, props } from '@ngrx/store';
import { CustomerMetadata } from '../interfaces';

export const loadCustomerMetadata = createAction('[CustomerMetadata] Load CustomerMetadata');

export const loadCustomerMetadataSuccess = createAction(
  '[CustomerMetadata] Load CustomerMetadata Success',
  props<{ customerMetadata: CustomerMetadata }>()
);

export const loadCustomerMetadataFailure = createAction(
  '[CustomerMetadata] Load CustomerMetadata Failure',
  props<{ error: any }>()
);
