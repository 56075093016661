import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { EnvService, Language, StorageKeys } from '@medrecord/core';
import { addErrorToast, addSuccessToast } from '@medrecord/tools-toast';
import { StorageService } from '@medrecord/tools-storage';
import { getErrorToastBodyUtil } from '@medrecord/tools-utils';
import { TranslateService } from '@ngx-translate/core';
import { MedrecordMoment } from '@medrecord/services-datetime';
import {
  changePasswordAction,
  changePasswordFailureAction,
  forgotPasswordAction,
  forgotPasswordFailureAction,
  forgotPasswordSuccessAction,
  restorePasswordAction,
  restorePasswordFailureAction,
  restorePasswordSuccessAction,
} from '../auth-manager.actions';
import { AuthRouteParams } from '../../models/enums';
import { PasswordService } from '../../services/password.service';

@Injectable()
export class PasswordEffects {
  @Effect()
  changePassword$ = this.actions$.pipe(
    ofType(changePasswordAction),
    switchMap(({ oldPassword, newPassword }) => {
      let languageCode = this.storage.getItem<Language>(StorageKeys.Language, Language.EN);
      if (this.envService.enableGermanLanguageAuth && languageCode === Language.DE) languageCode = Language.EN;

      return this.forgotPasswordService.changePassword({ oldPassword, newPassword, languageCode }).pipe(
        switchMap(() => [
          addSuccessToast({
            title: 'change_password_success_title',
            content: 'change_password_success_content',
          }),
          window.location.reload()
        ]),
        catchError(({ error }) => [
          changePasswordFailureAction({ error }),
          addErrorToast(getErrorToastBodyUtil('change_password_error', error)),
        ])
      );
    })
  );

  @Effect()
  forgotPassword$ = this.actions$.pipe(
    ofType(forgotPasswordAction),
    switchMap(({ email, successActions }) => {
      let languageCode = this.storage.getItem<Language>(StorageKeys.Language, Language.EN);
      if (this.envService.enableGermanLanguageAuth && languageCode === Language.DE) languageCode = Language.EN;

      return this.forgotPasswordService.forgotPassword({ languageCode, email }).pipe(
        switchMap(() => {
          const waitingTime = MedrecordMoment().add(3, 'minutes').toISOString();

          return [forgotPasswordSuccessAction({ config: { email, time: waitingTime } }), ...(successActions || [])];
        }),
        catchError(({ error }) => [
          forgotPasswordFailureAction({ error }),
          addErrorToast(getErrorToastBodyUtil('forgot_password_error', error)),
        ])
      );
    })
  );

  @Effect()
  restorePassword$ = this.actions$.pipe(
    ofType(restorePasswordAction),
    switchMap(({ newPassword, successActions, languageCode }) => {
      if (this.storage.getItem<Language>(StorageKeys.Language) !== Language.DE || !this.envService.enableGermanLanguageAuth)
        this.storage.setItem(StorageKeys.Language, languageCode);
      const oneTimeToken = new URL(document.location.href).searchParams.get(AuthRouteParams.RestoreToken);
      return this.forgotPasswordService
        .restorePassword({
          languageCode,
          newPassword,
          oneTimeToken: oneTimeToken || 'invalid',
        })
        .pipe(
          switchMap(() => {
            return [restorePasswordSuccessAction(), ...(successActions || [])];
          }),
          catchError(({ error }) => {
            let { title, content } = getErrorToastBodyUtil('restore_password_error', error);

            if (error.status === 500 && !oneTimeToken) {
              title = this.translateService.instant('invalid_token_error');
              content = this.translateService.instant('invalid_token_error_content');
            }

            return [restorePasswordFailureAction({ error }), addErrorToast({ title, content })];
          })
        );
    })
  );

  constructor(
    private forgotPasswordService: PasswordService,
    private storage: StorageService<StorageKeys>,
    private translateService: TranslateService,
    private actions$: Actions,
    private envService: EnvService
  ) {}
}
