import { encode as base64encode } from "base64-arraybuffer";
import * as faker from 'faker';

export const getOAuthConfigUtil = async (codeVerifier) => {
  const state = faker.random.word();
  const codeChallenge = await generateCodeChallenge(codeVerifier);

  return {
    codeChallenge: codeChallenge,
    state: state,
    redirectUri: document.location.origin
  };
};


export const generateCodeChallenge = async (codeVerifier: string): Promise<string> => {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const digest = await crypto.subtle.digest("SHA-256", data);
  const base64Digest = base64encode(digest);
  // you can extract this replacing code to a function
  return base64Digest
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");
};