import { AbstractHeadersManipulatorInterceptor } from '@medrecord/abstractions-interceptors';
import { HTTP_INTERCEPTORS, HttpHandler, HttpRequest } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap, switchMap, take } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { selectAccessToken } from '../state-management/auth-manager.selectors';
import { renewTokenAction, renewTokenSuccessAction } from '../state-management/auth-manager.actions';
import { Provider } from '@angular/core';
import { EnvService } from '@medrecord/core';

export const provideAuthorizationInterceptor = (): Provider => [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: class extends AbstractHeadersManipulatorInterceptor {
      constructor(private store: Store, private actions$: Actions,
                  private envService: EnvService,) {
        super();
      }

      intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        if (req.params.has('isCms')) {
          req = req.clone({
            params: req.params.delete('isCms'),
          });

          if (req.params.has('cmsAuthToken')) {
            const cmsAuthToken = req.params.get('cmsAuthToken');
            req = req.clone({
              params: req.params.delete('cmsAuthToken'),
              headers: req.headers.set('Authorization', cmsAuthToken),
            });
          }

          return next.handle(req);
        }

        return this.store.pipe(
          select(selectAccessToken),
          take(1),
          mergeMap((token) => {
            const reqCloneAfterTenant = this.envService.tenantId
              ? req.clone({
                setHeaders: { 'X-Tenant-ID': this.envService.tenantId }
              })
              : req;

            if (!token) {
              return next.handle(reqCloneAfterTenant);
            }

            const cloneWithToken = reqCloneAfterTenant.clone({
              setHeaders: { Authorization: `Bearer ${token}` }
            });

            return next.handle(cloneWithToken).pipe(
              catchError((err) => {
                if (err.status === 401) {
                  this.store.dispatch(renewTokenAction());

                  return this.actions$.pipe(
                    ofType(renewTokenSuccessAction),
                    take(1),
                    switchMap(({ accessToken }) => {
                      const requestWithUpdatedToken = req.clone({
                        setHeaders: { Authorization: `Bearer ${accessToken}` }
                      });

                      return next.handle(requestWithUpdatedToken);
                    })
                  );
                }

                return throwError(err);
              })
            );
          })
        );
      }
    },
    multi: true,
    deps: [Store, Actions, EnvService],
  },
];
