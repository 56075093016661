import { Injectable, Provider } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpEventType,
} from '@angular/common/http';
import { catchError, tap, finalize } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { hideSpinnerAction, showSpinnerAction } from '../state-management/spinner.actions';
import { EnvService } from '@medrecord/core';

@Injectable({
  providedIn: 'root',
})
export class SpinnerInterceptorService implements HttpInterceptor {
  skippedSpinners = 0;
  constructor(private store: Store, private envService: EnvService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let lastResponse: HttpEvent<any>;
    let error: HttpErrorResponse;

    /** TODO Remove this condition when statuses will be receiving with WebSocket */
    if (req.url === this.envService.backend + '/status') {
      return next.handle(req);
    }

    if (!req.params.get('skipSpinner')) this.showSpinner();

    if (req.params.has('skipSpinner')) {
      this.skippedSpinners = this.skippedSpinners + 1;
      req = req.clone({
        params: req.params.delete('skipSpinner'),
      });
    }

    return next.handle(req).pipe(
      tap((res: HttpEvent<any>) => {
        lastResponse = res;
        if (res instanceof HttpResponse) {
          if (this.skippedSpinners === 0) this.hideSpinner();
          this.skippedSpinners = this.skippedSpinners - 1 < 0 ? 0 : this.skippedSpinners - 1;
        }

        return of(res);
      }),
      catchError((err) => {
        error = err;

        if (this.skippedSpinners === 0) this.hideSpinner();
        this.skippedSpinners = this.skippedSpinners - 1 < 0 ? 0 : this.skippedSpinners - 1;

        return throwError(err);
      }),
      finalize(() => {
        if (lastResponse.type === HttpEventType.Sent && !error) {
          if (this.skippedSpinners === 0) this.hideSpinner();
          this.skippedSpinners = this.skippedSpinners - 1 < 0 ? 0 : this.skippedSpinners - 1;
        }
      })
    );
  }

  private showSpinner(): void {
    this.store.dispatch(showSpinnerAction());
  }

  private hideSpinner(): void {
    this.store.dispatch(hideSpinnerAction());
  }
}

export const provideSpinnerInterceptor = (): Provider => [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: SpinnerInterceptorService,
    multi: true,
  },
];
