import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastComponent } from './toast/toast.component';
import { StoreModule } from '@ngrx/store';
import { featureToastKey, reducer } from './toast.reducer';
import { ToastModule } from '@syncfusion/ej2-angular-notifications';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(featureToastKey, reducer),
    ToastModule
  ],
  declarations: [ToastComponent],
  exports: [ToastComponent],
})
export class MedrecordToastToolModule {}
