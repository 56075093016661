import { createAction, props } from '@ngrx/store';
import { Role } from '../../models/enums';
import { RenewTokenResponse, TokenResponse } from '../../models/interfaces';

/** Refresh Token */
export const renewTokenAction = createAction('[Auth Manager] Renew Token');

export const renewTokenSuccessAction = createAction(
  '[Auth Manager] Renew Token Successfully',
  props<RenewTokenResponse>()
);

export const renewTokenFailureAction = createAction(
  '[Auth Manager] Renew Token Failure',
  props<{ error: any }>()
);

/** Request Tokens */
export const requestTokensAction = createAction(
  '[Auth Manager] Request Tokens', 
  props<{ code: string, redirectPath?: string[], redirectQueryParams?: any }>()
);

export const requestTokensSuccessAction = createAction(
  '[Auth Manager] Request Tokens Successfully',
  props<TokenResponse>()
);

export const requestTokensFailureAction = createAction(
  '[Auth Manager] Request Tokens Failure',
  props<{ error: any }>()
);

/** Request WhoAmI */
export const requestWhoAmIAction = createAction(
  '[Auth Manager] Request WhoAmI',
  props<{ accessToken: string, redirectPath?: string[], redirectQueryParams?: any }>()
);

export const requestWhoAmISuccessAction = createAction(
  '[Auth Manager] Request WhoAmI Successfully',
  props<{  email: string, roles: Role[], userId: string }>()
);

export const requestWhoAmIFailureAction = createAction(
  '[Auth Manager] Request WhoAmI Failure',
  props<{ error: any }>()
);