import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as CustomerMetadataActions from './customer-metadata.actions';
import { CustomerMetadataService } from '../customer-metadata.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { ThemingService, PaletteVarNames, ClientColors, Colors, FullColor } from '@medrecord/services-colors';
import { camelCase } from 'lodash';

@Injectable()
export class CustomerMetadataEffects {
  @Effect()
  loadCustomerMetadata$ = this.actions$.pipe(
    ofType(CustomerMetadataActions.loadCustomerMetadata),
    switchMap(() =>
      this.service.fetchMetadata().pipe(
        map((customerMetadata) => {
          return CustomerMetadataActions.loadCustomerMetadataSuccess({
            customerMetadata: customerMetadata.data,
          });
        }),
        catchError(({ error }) => {
          return of(CustomerMetadataActions.loadCustomerMetadataFailure({ error }));
        })
      )
    )
  );

  @Effect()
  loadCustomerMetadataSuccess$ = this.actions$.pipe(
    ofType(CustomerMetadataActions.loadCustomerMetadataSuccess),
    switchMap(({ customerMetadata }) => {
      const colorNames: ClientColors[] = [
        'primary',
        'secondary',
        'link',
        'primary-button',
        'warning',
        'alert',
        'success',
        'error',
      ];

      colorNames.forEach((colorName) => {
        const paletteKey = camelCase(colorName);
        const colorVariants = [];
        if (paletteKey === 'primary') {
          colorVariants.push(...this.themingService.getPrimaryColorVariants(customerMetadata.palettes[0][paletteKey]));
          colorVariants.forEach((variant) => {
            this.setBodyCSSVariables(variant);
          });
        } else {
          this.themingService.saveHexColor(colorName, customerMetadata.palettes[0][paletteKey]);
          this.setBodyCSSVariables(colorName);
        }
      });

      this.title.setTitle(customerMetadata.general.shortName);
      CustomerMetadataEffects.setFavicon(customerMetadata.general.favicon);

      return [];
    })
  );

  static setFavicon(icon: string): void {
    if (!document.getElementById('favicon')) {
      const favicon = document.createElement('link');

      favicon.setAttribute('href', icon);
      favicon.setAttribute('type', 'image/x-icon');
      favicon.setAttribute('rel', 'icon');
      favicon.setAttribute('id', 'favicon');

      document.head.appendChild(favicon);
    }
  }

  private setBodyCSSVariables(colorName: Colors): void {
    const color: FullColor = this.themingService.getColor(colorName);
    const paletteKey = camelCase(colorName);

    document.body.style.setProperty(PaletteVarNames[paletteKey] + '-h', color.hsl.hue);
    document.body.style.setProperty(PaletteVarNames[paletteKey] + '-s', color.hsl.saturation);
    document.body.style.setProperty(PaletteVarNames[colorName] + '-l', color.hsl.lightness);
  }

  constructor(
    private title: Title,
    private actions$: Actions,
    private service: CustomerMetadataService,
    private themingService: ThemingService
  ) {}
}
