import { ErrorPayload, LOCAL_STORAGE_PREFIX, StorageKeys } from '@medrecord/core';
import { NgRxStorageSyncConfig } from '@medrecord/tools-ngrx-sync-storage';
import { Action, createReducer, on } from '@ngrx/store';
import * as faker from 'faker';
import { OAuthSignInResponse } from '../models/interfaces';
import { AuthStorageItem } from '../models/interfaces/auth-storage-item.interface';
import { signInWithTempTokenSuccessAction } from './actions/signin.actions';
import {
    changePasswordFailureAction,
    clearError,
    completeSignIn,
    confirmEmailFailure,
    confirmEmailSuccess,
    confirmedPrivacyPolicyAction,
    createCodeVerifier,
    forgotPasswordSuccessAction,
    initPhone,
    logoutFinished,
    navigateToHealthTalkFailureAction,
    removePassedDelayAction,
    renewTokenFailureAction,
    renewTokenSuccessAction,
    requestTokensFailureAction,
    requestTokensSuccessAction,
    requestWhoAmIFailureAction,
    requestWhoAmISuccessAction,
    resetSignInData,
    resetSignUpData,
    selectRoleAction,
    signInFailureAction,
    signInSuccessAction,
    signInViaGoogle,
    signInViaGoogleCompleteSuccess,
    signInViaGoogleFailure,
    signUpFailureAction,
    signUpSuccessAction
} from './auth-manager.actions';

export const featureAuthMangerKey = 'auth-manager';

export interface AuthManagerState extends OAuthSignInResponse, AuthStorageItem {
  error?: ErrorPayload;
  finished?: boolean;
  confirmedEmail?: boolean;
}

export const initialState: AuthManagerState = {
  phone: '',
  email: '',
  roles: [],
  userId: '',
  accessToken: '',
  expiresIn: 0,
  redirectUri: '',
  refreshExpiresIn: 0,
  refreshToken: '',
  session: '',
  tokenType: '',
  codeVerifier: '',
  selectedRole: null,
  twoFactorStatus: undefined,
  error: null,
  finished: false,
  confirmedPrivacyPolicy: false,
  rememberMe: null,
  forgotPasswordDelay: {},
  confirmedEmail: undefined,
};

export const authMangerReducer = createReducer(
  initialState,
  on(completeSignIn, (state: AuthManagerState, { redirectUri, session, twoFactorStatus }) => ({
    ...state,
    redirectUri,
    session,
    twoFactorStatus,
  })),
  on(initPhone, (state: AuthManagerState, { phone }) => ({
    ...state,
    phone,
  })),
  on(signUpSuccessAction, (state: AuthManagerState) => ({
    ...state,
    finished: true,
  })),
  on(signInViaGoogle, (state: AuthManagerState) => ({
    ...state,
    rememberMe: initialState.rememberMe,
    session: initialState.session,
  })),
  on(confirmEmailSuccess, (state: AuthManagerState) => ({
    ...state,
    confirmedEmail: true,
  })),

  on(
    requestTokensSuccessAction,
    (state: AuthManagerState, { access_token, expires_in, refresh_expires_in, refresh_token }) => ({
      ...state,
      accessToken: access_token,
      expiresIn: expires_in,
      refreshExpiresIn: refresh_expires_in,
      refreshToken: refresh_token,
    })
  ),

  on(signInSuccessAction, (state, { session, twoFactorStatus, redirectUri, rememberMe, email }) => ({
    ...state,
    session,
    twoFactorStatus,
    redirectUri,
    rememberMe: rememberMe ? email : null,
  })),

  on(signInWithTempTokenSuccessAction, (state, { session, twoFactorStatus, redirectUri }) => ({
    ...state,
    session,
    twoFactorStatus,
    redirectUri,
  })),

  on(signInViaGoogleCompleteSuccess, (state, { session, twoFactorStatus, redirectUri }) => ({
    ...state,
    session,
    twoFactorStatus,
    redirectUri,
  })),

  on(renewTokenSuccessAction, (state, { accessToken }) => ({ ...state, accessToken })),

  on(forgotPasswordSuccessAction, (state, { config }) => ({
    ...state,
    forgotPasswordDelay: {
      ...state.forgotPasswordDelay,
      [config.email]: config.time,
    },
  })),
  on(removePassedDelayAction, (state, { email }) => ({
    ...state,
    forgotPasswordDelay: Object.keys(state.forgotPasswordDelay).reduce((acc, key) => {
      if (key !== email) {
        acc[key] = state.forgotPasswordDelay[key];
      }

      return acc;
    }, {}),
  })),

  on(selectRoleAction, (state, { role }) => ({ ...state, selectedRole: role })),

  on(createCodeVerifier, (state) => ({ ...state, codeVerifier: faker.random.word() })),

  on(clearError, (state) => ({ ...state, error: null })),

  on(confirmedPrivacyPolicyAction, (state, { confirmed }) => ({ ...state, confirmedPrivacyPolicy: confirmed })),

  on(requestWhoAmISuccessAction, (state: AuthManagerState, { userId, email, roles }) => ({
    ...state,
    userId,
    email,
    roles,
  })),

  on(
    changePasswordFailureAction,
    signInFailureAction,
    renewTokenFailureAction,
    requestTokensFailureAction,
    requestWhoAmIFailureAction,
    signInViaGoogleFailure,
    signUpFailureAction,
    confirmEmailFailure,
    navigateToHealthTalkFailureAction,
    (state: AuthManagerState, { error }) => ({ ...state, error, confirmedEmail: false })
  )
);

export function createAuthMangerReducer(state: AuthManagerState, action: Action): any {
  return authMangerReducer(state, action);
}

export const authMangerSyncConfig: NgRxStorageSyncConfig = {
  initialState,
  prefixStorageKey: LOCAL_STORAGE_PREFIX,
  storageKey: StorageKeys.Auth,
  storeName: featureAuthMangerKey,
  skipKeys: ['error'],
  rehydrate: new Map([
    [
      logoutFinished.type,
      {
        excludeKeys: ['rememberMe', 'session'],
      },
    ],
    [
      resetSignInData.type,
      {
        excludeKeys: [
          'rememberMe',
          'session',
          'accessToken',
          'refreshToken',
          'expiresIn',
          'refreshExpiresIn',
          'tokenType',
          'userId',
          'roles',
          'email',
        ],
      },
    ],
    [
      resetSignUpData.type,
      {
        rehydrateKeys: ['selectedRole', 'confirmedPrivacyPolicy'],
      },
    ],
  ]),
};
