import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialLoginModule } from 'angularx-social-login';
import { SocialAuthService } from './services/social-auth.service';

@NgModule({
  imports: [
    CommonModule,
    SocialLoginModule
  ],
  providers: [
    SocialAuthService
  ]
})
export class MedrecordSocialLoginModule {
  static forRoot(): ModuleWithProviders<MedrecordSocialLoginModule> {
    return {
      ngModule: MedrecordSocialLoginModule,
      providers: [SocialAuthService],
    };
  }
}
