import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { EnvService, Language, StorageKeys } from '@medrecord/core';
import { StorageService } from '@medrecord/tools-storage';
import { addErrorToast } from '@medrecord/tools-toast';
import { getErrorToastBodyUtil } from '@medrecord/tools-utils';
import { TranslateService } from '@ngx-translate/core';
import {
  setPasswordAction,
  acceptInvitationFailureAction,
  acceptInvitationSuccessAction,
} from '../auth-manager.actions';
import { AuthRouteParams } from '../../models/enums';
import { InvitationService } from '../../services/invitation.service';

@Injectable()
export class InvitationEffects {
  @Effect()
  restorePassword$ = this.actions$.pipe(
    ofType(setPasswordAction),
    switchMap(({ newPassword, successActions, languageCode }) => {
      if (
        this.storage.getItem<Language>(StorageKeys.Language) !== Language.DE ||
        !this.envService.enableGermanLanguageAuth
      )
        this.storage.setItem(StorageKeys.Language, languageCode);
      const oneTimeToken = new URL(document.location.href).searchParams.get(AuthRouteParams.RestoreToken);
      return this.invitationService
        .acceptInvitation({
          languageCode,
          newPassword,
          oneTimeToken: oneTimeToken || 'invalid',
        })
        .pipe(
          switchMap(() => {
            return [acceptInvitationSuccessAction(), ...(successActions || [])];
          }),
          catchError(({ error }) => {
            let { title, content } = getErrorToastBodyUtil('auth_accept_invitation_error', error);

            if (error.status === 500 && !oneTimeToken) {
              title = this.translateService.instant('invalid_token_error');
              content = this.translateService.instant('invalid_token_error_content');
            }

            return [acceptInvitationFailureAction({ error }), addErrorToast({ title, content })];
          })
        );
    })
  );

  constructor(
    private invitationService: InvitationService,
    private storage: StorageService<StorageKeys>,
    private translateService: TranslateService,
    private actions$: Actions,
    private envService: EnvService
  ) {}
}
