import { APP_INITIALIZER, Provider } from '@angular/core';
import { StorageService } from '@medrecord/tools-storage';
import { StorageKeys } from '@medrecord/core';

export function provideMarkOAuthFactory(storage: StorageService<StorageKeys>): Function {
  return () => new Promise((resolve) => {
    storage.setItem(StorageKeys.isOAuth, true);
    resolve();
  });
}

export function provideMarkOAuth(): Provider[] {
  return [
    {
      provide: APP_INITIALIZER,
      useFactory: provideMarkOAuthFactory,
      deps: [StorageService],
      multi: true
    }
  ];
}