import {
  oAuthSignInAction,
  signInAction,
  signInFailureAction,
  signInSuccessAction,
  signInViaGoogle,
  signInViaGoogleCompleteFailure,
  signInViaGoogleCompleteSuccess,
  signInViaGoogleFailure,
  signInViaGoogleSuccess,
  signInViaMedrecord,
  signInViaMedrecordFailure,
  signInViaMedrecordSuccess,
  signInWithCredentialsAction,
  signInWithSessionAction,
} from '../auth-manager.actions';
import { StorageService } from '@medrecord/tools-storage';
import { catchError, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { SignInService } from '../../services/signin.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { getGoogleAuthError } from '../../utils/google-auth.util';
import { TranslateService } from '@ngx-translate/core';
import { ErrorCode, Language, StorageKeys } from '@medrecord/core';
import { SocialAuthService } from '@medrecord/services-social-login';
import { addErrorToast } from '@medrecord/tools-toast';
import { getErrorToastBodyUtil } from '@medrecord/tools-utils';
import { getCodeVerifier, getRememberedUser, selectSession } from '../auth-manager.selectors';
import { Store } from '@ngrx/store';
import { AuthRouteParams } from '../../models/enums';
import { from } from 'rxjs';
import { MedrecordOAuthService } from '../../services/medrecord-oauth.service';
import {
  oAuthSignInWithTempTokenAction,
  signInRememberedUserAction,
  signInWithTempTokenAction,
  signInWithTempTokenFinishedAction,
  signInWithTempTokenSuccessAction,
} from '../actions/signin.actions';

@Injectable()
export class SignInEffects {
  @Effect()
  login$ = this.actions$.pipe(
    ofType(signInAction),
    switchMap((payload) => {
      return [signInWithCredentialsAction(payload)];
    })
  );

  @Effect()
  loginRememberedUser$ = this.actions$.pipe(
    ofType(signInRememberedUserAction),
    withLatestFrom(this.store.select(getRememberedUser)),
    switchMap(([, rememberedUser]) => {
      if (rememberedUser) {
        return [signInWithSessionAction({ email: rememberedUser, password: '', rememberMe: true })];
      }

      return [addErrorToast(getErrorToastBodyUtil('login_error', { message: 'User not found', status: '404' }))];
    })
  );

  @Effect()
  loginWithCredentials$ = this.actions$.pipe(
    ofType(signInWithCredentialsAction),
    withLatestFrom(this.store.select(getCodeVerifier)),
    switchMap(([payload, codeVerifier]) => {
      return from(this.signInService.getOAuthSignInPayload(codeVerifier)).pipe(
        switchMap((oAuthConfig) =>
          this.signInService.signIn(payload.email, payload.password, oAuthConfig).pipe(
            switchMap((response) => {
              if (this.storage.getItem<boolean | undefined>(StorageKeys.isOAuth)) {
                return [oAuthSignInAction({ ...response, ...payload })];
              }

              return [signInSuccessAction({ ...response, ...payload })];
            }),
            catchError(({ error }) => [
              signInFailureAction({ error }),
              addErrorToast(getErrorToastBodyUtil('login_error', error)),
            ])
          )
        )
      );
    })
  );

  @Effect()
  loginWithSession$ = this.actions$.pipe(
    ofType(signInWithSessionAction),
    withLatestFrom(this.store.select(selectSession), this.store.select(getCodeVerifier)),
    mergeMap(([payload, session, codeVerifier]) => {
      return from(this.signInService.getOAuthSignInPayload(codeVerifier)).pipe(
        switchMap((oAuthConfig) =>
          this.signInService.signInWithSession(session, oAuthConfig).pipe(
            switchMap((response) => {
              if (this.storage.getItem<boolean | undefined>(StorageKeys.isOAuth)) {
                return [oAuthSignInAction({ ...response, ...payload })];
              }

              return [signInSuccessAction({ ...response, ...payload })];
            }),
            catchError(() => [signInWithCredentialsAction(payload)])
          )
        )
      );
    })
  );

  @Effect()
  loginWithTempToken$ = this.actions$.pipe(
    ofType(signInWithTempTokenAction),
    withLatestFrom(this.store.select(getCodeVerifier)),
    switchMap(([payload, codeVerifier]) => {
      return from(this.signInService.getOAuthSignInPayload(codeVerifier)).pipe(
        switchMap((oAuthConfig) =>
          this.signInService.signInWithTempToken(payload.tempToken, oAuthConfig).pipe(
            switchMap((response) => {
              if (this.storage.getItem<boolean | undefined>(StorageKeys.isOAuth)) {
                return [oAuthSignInWithTempTokenAction({ ...response, ...payload })];
              }

              return [signInWithTempTokenFinishedAction({ ...response, ...payload, success: true })];
            }),
            catchError(({ error }) => [
              signInWithTempTokenFinishedAction({ ...payload, success: false }),
              signInFailureAction({ error: error }),
              addErrorToast(getErrorToastBodyUtil('login_error', error)),
            ])
          )
        )
      );
    })
  );

  @Effect()
  oAuthLogin = this.actions$.pipe(
    ofType(oAuthSignInAction),
    switchMap((payload) => {
      if (this.isOauthStateValid(new URL(payload.redirectUri).searchParams.get(AuthRouteParams.State))) {
        return [signInSuccessAction(payload)];
      }

      return [
        signInFailureAction({ error: this.translateService.instant('oauth_state_error') }),
        addErrorToast(
          getErrorToastBodyUtil('login_error', {
            message: 'State is Missed',
            status: 'State is Missed',
            code: ErrorCode.StateMissed,
          })
        ),
      ];
    })
  );

  @Effect()
  oAuthLoginWithTempToken$ = this.actions$.pipe(
    ofType(oAuthSignInWithTempTokenAction),
    switchMap((payload) => {
      if (this.isOauthStateValid(new URL(payload.redirectUri).searchParams.get(AuthRouteParams.State))) {
        return [
          signInWithTempTokenFinishedAction({ ...payload, success: true }),
          signInWithTempTokenSuccessAction(payload),
        ];
      }

      return [
        signInWithTempTokenFinishedAction({ ...payload, success: false }),
        signInFailureAction({ error: this.translateService.instant('oauth_state_error') }),
        addErrorToast(
          getErrorToastBodyUtil('login_error', {
            message: 'State is Missed',
            status: 'State is Missed',
            code: ErrorCode.StateMissed,
          })
        ),
      ];
    })
  );

  @Effect()
  loginViaMedrecord$ = this.actions$.pipe(
    ofType(signInViaMedrecord),
    withLatestFrom(this.store.select(getCodeVerifier)),
    switchMap(([, codeVerifier]) => {
      return this.medrecordOAuthService.loginWithMedrecord(codeVerifier).pipe(
        switchMap((idToken: string) => {
          return [
            signInViaMedrecordSuccess({
              idToken,
              languageCode: this.storage.getItem(StorageKeys.Language),
            }),
          ];
        }),
        catchError(({ error }) => {
          if (error === 'popup_closed_by_user') {
            return [];
          }

          return [
            signInViaMedrecordFailure({ error: error }),
            addErrorToast(getErrorToastBodyUtil('login_error', error)),
          ];
        })
      );
    })
  );

  @Effect()
  loginViaGoogle$ = this.actions$.pipe(
    ofType(signInViaGoogle),
    switchMap(() =>
      this.socialAuthService.signInWithGoogle().pipe(
        switchMap((idToken: string) => {
          return [
            signInViaGoogleSuccess({
              idToken,
              languageCode: this.storage.getItem(StorageKeys.Language),
            }),
          ];
        }),
        catchError(({ error }) => {
          if (error === 'popup_closed_by_user') {
            return [];
          }

          return [
            signInViaGoogleFailure({ error: getGoogleAuthError(error, this.translateService) }),
            addErrorToast(getErrorToastBodyUtil('login_with_google_error', error)),
          ];
        })
      )
    )
  );

  @Effect()
  loginViaGoogleFinish$ = this.actions$.pipe(
    ofType(signInViaGoogleSuccess),
    withLatestFrom(this.store.select(getCodeVerifier)),
    switchMap(([payload, codeVerifier]) =>
      from(this.signInService.getOAuthSignInPayload(codeVerifier)).pipe(
        switchMap((oAuthConfig) =>
          this.signInService
            .loginViaGoogle(payload.idToken, this.storage.getItem(StorageKeys.Language, Language.EN), oAuthConfig)
            .pipe(
              switchMap(({ session, twoFactorStatus, redirectUri }) => {
                if (this.isOauthStateValid(new URL(redirectUri).searchParams.get(AuthRouteParams.State))) {
                  return [signInViaGoogleCompleteSuccess({ session, twoFactorStatus, redirectUri })];
                }
              }),
              catchError(({ error }) => [
                signInViaGoogleCompleteFailure({ error }),
                addErrorToast(getErrorToastBodyUtil('login_with_google_error', error)),
              ])
            )
        )
      )
    )
  );

  private isOauthStateValid(state: string): boolean {
    if (state && this.storage.getItem(StorageKeys.State)) {
      return this.storage.getItem<string>(StorageKeys.State) === state;
    }
    return true;
  }

  constructor(
    private signInService: SignInService,
    private socialAuthService: SocialAuthService,
    private medrecordOAuthService: MedrecordOAuthService,
    private storage: StorageService<StorageKeys>,
    private actions$: Actions,
    private store: Store,
    private translateService: TranslateService
  ) {}
}
