import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import { LogoutService } from '../../services/logout.service';
import { logout, logoutEverywhere, logoutFinished } from '../auth-manager.actions';
import { getRememberedUser, selectAccessToken, selectSession } from '../auth-manager.selectors';
import { Store } from '@ngrx/store';
import { addErrorToast } from '@medrecord/tools-toast';
import { getErrorToastBodyUtil } from '@medrecord/tools-utils';
import { StorageService } from '@medrecord/tools-storage';
import { StorageKeys } from '@medrecord/core';
import { of } from 'rxjs';

@Injectable()
export class LogoutEffects {
  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logout),
      withLatestFrom(
        this.store.select(selectAccessToken),
        this.store.select(selectSession),
        this.store.select(getRememberedUser)
      ),
      switchMap(([, accessToken, session, rememberMe]) => {
        const logoutMethod = accessToken
          ? this.logoutService.signOut()
          : (rememberMe ? of(null) : this.logoutService.signOutWithSession(session));
        return logoutMethod.pipe(
          switchMap(() => {
            this.storage.removeItem(StorageKeys.PinnedUsers);
            this.storage.removeItem(StorageKeys.State);
            this.storage.removeItem(StorageKeys.Role);
            this.storage.removeItem(StorageKeys.ChatBot);

            return [logoutFinished()];
          }),
          catchError(({ error }) => [addErrorToast(getErrorToastBodyUtil('logout_error', error))])
        );
      })
    )
  );

  logoutEverywhere$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutEverywhere),
      switchMap(() =>
        this.logoutService.signOut().pipe(
          switchMap(() => {
            this.storage.removeItem(StorageKeys.PinnedUsers);
            this.storage.removeItem(StorageKeys.State);
            this.storage.removeItem(StorageKeys.Role);
            this.storage.removeItem(StorageKeys.ChatBot);

            return [logoutFinished()];
          }),
          catchError(({ error }) => [addErrorToast(getErrorToastBodyUtil('logout_everywhere_error', error))])
        )
      )
    )
  );

  constructor(
    private logoutService: LogoutService,
    private actions$: Actions,
    private store: Store,
    private storage: StorageService<StorageKeys>
  ) {
  }
}
