import { Inject, Injectable } from '@angular/core';
import { EnvService } from '@medrecord/core';
import { AUTH_ROUTE_NAMES, AuthRouteNames } from '@medrecord/routes-auth';
import { AuthRouteParams } from '../models/enums';

@Injectable()
export class AuthRedirectService {
  constructor(
    @Inject(AUTH_ROUTE_NAMES) private authRouteNames: AuthRouteNames,
    private envService: EnvService
  ) {}

  async navigateToRestorePassword(): Promise<URL> {
    const url = new URL(
      `${this.envService.oauth}/${this.authRouteNames.Password.Forgot}/${this.authRouteNames.Password.Restore}`
    );

    const restoreToken = new URL(document.location.href).searchParams.get(AuthRouteParams.RestoreToken);

    url.searchParams.set(AuthRouteParams.RestoreToken, restoreToken);

    return url;
  }

}
