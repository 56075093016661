import { Action, createReducer, on } from '@ngrx/store';
import {
  setTwofaFlowAction,
  setTwofaQrCodeAction,
  setTwofaStepAction,
  setTwofaCodesToRememberAction,
} from './actions/twofa.actions';
import { TwofaFlow } from '../models/enums/twofa-flow.enum';

export const twofaFeatureKey = 'twofa';

export interface State {
  flow: TwofaFlow;
  step: number;
  qrCodeUri: string;
  codesToRemember: string[];
  error: any;
}

export const initialState: State = {
  flow: null,
  step: 1,
  qrCodeUri: '',
  codesToRemember: [],
  error: null
};

const _twofaReducer = createReducer(
  initialState,
  on(setTwofaFlowAction, (state, { flow }) => ({
    ...state,
    flow,
    step: flow === TwofaFlow.Reconfiguration ? 3 : 1,
    error: null
  })),
  on(setTwofaStepAction, (state, { step }) => ({
    ...state,
    step,
    error: null
  })),
  on(setTwofaQrCodeAction, (state, { qrCodeUri }) => ({
    ...state,
    qrCodeUri,
    error: null
  })),
  on(setTwofaCodesToRememberAction, (state, { codesToRemember }) => ({
    ...state,
    codesToRemember,
    error: null
  })),
);

export function twofaReducer(state: State, action: Action): State {
  return _twofaReducer(state, action);
}
