import { createAction, props } from '@ngrx/store';
import { AuthFormModel, GoogleOAuthSignUpPayload } from '../../models/interfaces';
import { WithSuccessActions } from '@medrecord/core';
import { Role } from '../../models/enums';

export const resetSignUpData = createAction('[Auth Manager] Reset Sign Up Data');

export const selectRoleAction = createAction('[Auth Manager] Select Role', props<{ role: Role }>());

export const confirmedPrivacyPolicyAction = createAction(
  '[Auth Manager] Confirmed Privacy Policy',
  props<{ confirmed: boolean }>()
);

export const clearError = createAction('[Auth Manager] Clear error');

export const signUpAction = createAction('[Auth Manager] Sign Up', props<WithSuccessActions<AuthFormModel>>());

export const signUpSuccessAction = createAction('[Auth Manager] Sign Up Success');

export const signUpFailureAction = createAction('[Auth Manager] Sign Up Failure', props<{ error: any }>());

export const signUpViaGoogle = createAction('[Auth Manager] Sign Up Via Google', props<WithSuccessActions<{}>>());

export const signUpViaGoogleSuccess = createAction(
  '[Auth Manager] Sign Up Via Google Success',
  props<WithSuccessActions<GoogleOAuthSignUpPayload>>()
);

export const signUpViaGoogleFailure = createAction(
  '[Auth Manager] Sign Up Via Google Failure',
  props<{ error: any }>()
);

export const confirmEmail = createAction(
  '[Auth Manager] Confirm Email',
  props<WithSuccessActions<{ token: string }>>()
);

export const confirmEmailSuccess = createAction('[Auth Manager] Confirm Email Success');

export const confirmEmailFailure = createAction('[Auth Manager] Confirm Email Failure', props<{ error: any }>());

export const signUpByAdminInvite = createAction(
  '[Auth Manager] Sign Up by Admin Invite',
  props<WithSuccessActions<{ password: string; token: string }>>()
);

export const signUpByAdminInviteSuccess = createAction('[Auth Manager] Sign Up by Admin Invite Success');

export const resendVerificationEmailAction = createAction(
  '[Auth Manager] Resend Verification Email Action',
  props<{ email: string; redirectUri?: string }>()
);

export const resendVerificationEmailFailed = createAction(
  '[Auth Manager] Resend Verification Email Failed',
  props<{ error: any }>()
);
