import { DatePrecision, Language, EffectiveDate } from './typings';
import { MedrecordMoment } from './medrecord-moment.config';

const precisionDateFormats = {
  [DatePrecision.second]: 'DD/MM/yyyy HH:mm',
  [DatePrecision.day]: 'DD/MM/yyyy',
  [DatePrecision.month]: 'MM/yyyy',
  [DatePrecision.year]: 'yyyy',
};

// Helper method used to generate effective date type date time
export function setEffectiveDateWithPrecision(
  effectiveDate: EffectiveDate,
  aroundTranslation = '',
  locale: Language = MedrecordMoment.locale() as Language
): EffectiveDate {
  if (!effectiveDate) {
    return null;
  }

  const preparedEffectiveDate = effectiveDate || ({} as EffectiveDate);

  const preparedDate = preparedEffectiveDate.date ? preparedEffectiveDate.date : new Date();
  const date = MedrecordMoment(preparedDate).utc(true).locale(locale);

  return {
    precision: effectiveDate.precision,
    date: [DatePrecision.month, DatePrecision.year].includes(preparedEffectiveDate.precision)
      ? `'${aroundTranslation}' ${date.format(precisionDateFormats[preparedEffectiveDate.precision])}`
      : date.format(precisionDateFormats[preparedEffectiveDate.precision]),
  };
}

export function getDateMaskWithPrecision(precision: DatePrecision, aroundTranslation = ''): string {
  switch (precision) {
    case DatePrecision.month:
      return `'${aroundTranslation}' MM/yyyy`;
    case DatePrecision.year:
      return `'${aroundTranslation}' yyyy`;
    case DatePrecision.day:
      return 'dd/MM/yyyy';
    default:
      return 'dd/MM/yyyy HH:mm';
  }
}
