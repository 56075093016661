import { Pipe, PipeTransform } from '@angular/core';
import { MedrecordMoment } from '@medrecord/services-datetime';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'yearsOld'
})
export class YearsOldPipe implements PipeTransform {
  
  constructor(private translateService: TranslateService) {}
  
  transform(date: string | Date): string {
    if (!date) { return ''; }
  
    const difference = MedrecordMoment().diff(date, 'years');
    if (difference < 1) {
      return this.translateService.instant('profile_less_than_year');
    }
  
    return this.translateService.instant('profile_years', {
      years: MedrecordMoment.duration(difference, 'years').years()
    });
  }
}
