import { createSelector } from '@ngrx/store';
import { AuthManagerState, featureAuthMangerKey } from './auth-manager.reducers';
import { Role, VerificationStatus } from '../models/enums';
import { ErrorCode, StorageKeys } from '@medrecord/core';

const selectAuth = (state: AuthManagerState) => state[featureAuthMangerKey];

export const selectAccessToken = createSelector(
  selectAuth,
  (state: AuthManagerState): string => state.accessToken || (localStorage.getItem(StorageKeys.Auth) as any)?.accessToken
);

export const selectRefreshToken = createSelector(selectAuth, (state: AuthManagerState): string => state.refreshToken);

export const selectTwoFactorStatus = createSelector(
  selectAuth,
  (state: AuthManagerState): VerificationStatus => state.twoFactorStatus
);

export const getSelectedRole = createSelector(selectAuth, (state: AuthManagerState): Role => state.selectedRole);

export const getRememberedUser = createSelector(selectAuth, (state: AuthManagerState): string => state.rememberMe);

export const selectSession = createSelector(selectAuth, (state: AuthManagerState): string => state.session);

export const selectRoles = createSelector(selectAuth, (state: AuthManagerState): Role[] => state.roles);

export const selectUserId = createSelector(selectAuth, (state: AuthManagerState): string => state.userId);

export const getCodeVerifier = createSelector(selectAuth, (state: AuthManagerState): string => state.codeVerifier);

export const selectRedirectUri = createSelector(selectAuth, (state: AuthManagerState): string => state.redirectUri);

export const selectPhone = createSelector(selectAuth, (state: AuthManagerState): string => state.phone);

export const getFinishedSignup = createSelector(selectAuth, (state: AuthManagerState): boolean => state.finished);

export const getFinishedConfirmEmail = createSelector(
  selectAuth,
  (state: AuthManagerState): undefined | boolean => state.confirmedEmail
);
export const getErrorCode = createSelector(selectAuth, (state: AuthManagerState): ErrorCode => state.error?.code);
