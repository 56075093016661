import { Injectable } from '@angular/core';
import { EnvService, MedrecordResponse } from '@medrecord/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  VerificationCodeCheckPayload,
  VerificationPhonePayload,
  VerificationPhoneSavePayload,
  VerificationSessionPayload
} from '../models/interfaces';

@Injectable()
export class PhoneVerificationService {
  constructor(
    private http: HttpClient,
    private envService: EnvService
  ) {}
  
  /**
   * Get Phone Verification Code
   *
   * @desc If 2FA is enabled and user has assigned phone number, this endpoint sends verification code to it
   * */
  requestCode(sessionPayload: VerificationSessionPayload): Observable<MedrecordResponse> {
    return this.http.post<MedrecordResponse>(
      `${this.envService.auth}/verification/phone/code`,
      sessionPayload
    );
  }
  
  /**
   *  Check Phone Verification Code
   *
   *  @desc If 2FA is enabled and user has assigned phone number, this endpoint checks if provided verification code
   *  is the same as sent to the stored phone number (max 3 attempts)
   * */
  checkCode(checkPayload: VerificationCodeCheckPayload): Observable<MedrecordResponse> {
    return this.http.post<MedrecordResponse>(
      `${this.envService.auth}/verification/phone/codecheck`,
      checkPayload
    );
  }
  
  /**
   *  Init Phone
   *
   *  @desc If 2FA is enabled and user doesn't have assigned phone number, this endpoint gets phone number and sends
   *  verification code to it
   * */
  initPhone(phonePayload: VerificationPhonePayload): Observable<MedrecordResponse> {
    return this.http.post<MedrecordResponse>(
      `${this.envService.auth}/verification/phone/init`,
      phonePayload
    );
  }
  
  /**
   *  Save Phone
   *
   *  @desc If 2FA is enabled and user doesn't have assigned phone number, this endpoint checks phone number and code
   *  sent to verify this number. If code is OK, then phone number is stored and session marked as fully authenticated
   * */
  savePhone(phonePayload: VerificationPhoneSavePayload): Observable<MedrecordResponse> {
    return this.http.post<MedrecordResponse>(
      `${this.envService.auth}/verification/phone/save`,
      phonePayload
    );
  }
  
  /**
   *  Init Phone Change
   *
   *  @desc If 2FA is enabled and user doesn't have assigned phone number, this endpoint gets phone number and sends
   *  verification code to it
   * */
  initPhoneChange(phonePayload: VerificationPhonePayload): Observable<MedrecordResponse> {
    return this.http.post<MedrecordResponse>(
      `${this.envService.auth}/verification/phone/change/init`,
      phonePayload
    );
  }
  
  /**
   *  Phone Change Save
   *
   *  @desc If 2FA is enabled and user doesn't have assigned phone number, this endpoint checks phone number and code
   *  sent to verify this number. If code is OK, then phone number is stored and session marked as fully authenticated
   * */
  savePhoneChange(phonePayload: VerificationPhoneSavePayload): Observable<MedrecordResponse> {
    return this.http.post<MedrecordResponse>(
      `${this.envService.auth}/verification/phone/change/save`,
      phonePayload
    );
  }
}