import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace'
})
export class ReplacePipe implements PipeTransform {
  transform(text: string, str:string, newString: string): string {
    return text.split(str).join(newString);
  }
}
