import { Pipe, PipeTransform } from '@angular/core';
import { convertToBase64DataUri } from '@medrecord/tools-utils';

@Pipe({
  name: 'base64String'
})
export class ConvertToBase64StringPipe implements PipeTransform {
  transform(photoBase64Data: string, photoContentType?: any): string {
    return convertToBase64DataUri(photoBase64Data, photoContentType);
  }
}
