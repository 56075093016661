import { Injectable } from '@angular/core';
import {
  confirmEmail,
  confirmEmailFailure,
  confirmEmailSuccess,
  signUpAction,
  signUpByAdminInvite,
  signUpByAdminInviteSuccess,
  signUpFailureAction,
  signUpSuccessAction,
  signUpViaGoogle,
  signUpViaGoogleFailure,
  signUpViaGoogleSuccess,
  resendVerificationEmailAction,
} from '../auth-manager.actions';
import { getSelectedRole } from '../auth-manager.selectors';
import { Store } from '@ngrx/store';
import { StorageService } from '@medrecord/tools-storage';
import { catchError, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SignUpService } from '../../services/signup.service';
import { Language, StorageKeys } from '@medrecord/core';
import { getGoogleAuthError } from '../../utils/google-auth.util';
import { TranslateService } from '@ngx-translate/core';
import { SocialAuthService } from '@medrecord/services-social-login';
import { addErrorToast, addSuccessToast } from '@medrecord/tools-toast';
import { getErrorToastBodyUtil } from '@medrecord/tools-utils';
import { of } from 'rxjs';
import { Role } from '../../models/enums';

@Injectable()
export class SignUpEffects {
  signup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signUpAction),
      withLatestFrom(this.store.select(getSelectedRole)),
      mergeMap(([payload, role]) =>
        this.signUpService
          .signup(
            payload.email,
            payload.password,
            this.storage.getItem(StorageKeys.Language, Language.EN),
            this.storage.getItem(StorageKeys.isOAuth, false) ? [Role.Patient] : [role]
          )
          .pipe(
            switchMap(() => [signUpSuccessAction(), ...(payload.successActions || [])]),
            catchError(({ error }: any) => [
              signUpFailureAction({ error }),
              addErrorToast(getErrorToastBodyUtil('signup_error', error)),
            ])
          )
      )
    )
  );

  signupViaGoogle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signUpViaGoogle),
      withLatestFrom(this.store.select(getSelectedRole)),
      switchMap(([payload, role]) => {
        return this.socialAuthService.signInWithGoogle().pipe(
          switchMap((idToken) => {
            return [
              signUpViaGoogleSuccess({
                idToken: idToken,
                languageCode: this.storage.getItem(StorageKeys.Language),
                roles: [role],
                successActions: payload.successActions || [],
              }),
            ];
          }),
          catchError((error: any) => [
            signUpViaGoogleFailure({ error: getGoogleAuthError(error, this.translateService) }),
            addErrorToast(getErrorToastBodyUtil('signup_with_google_error', error)),
          ])
        );
      })
    )
  );

  signupViaGoogleFinish$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signUpViaGoogleSuccess),
      mergeMap(({ idToken, successActions, roles }) =>
        this.signUpService
          .signupViaGoogle(idToken, this.storage.getItem(StorageKeys.Language, Language.EN), roles)
          .pipe(
            switchMap(() => [signUpSuccessAction(), ...successActions]),
            catchError(({ error }) => [
              signUpViaGoogleFailure({ error: getGoogleAuthError(error, this.translateService) }),
              addErrorToast(getErrorToastBodyUtil('signup_with_google_error', error)),
            ])
          )
      )
    )
  );

  confirmEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(confirmEmail),
      switchMap((action) => {
        return this.signUpService.confirmEmail(action.token).pipe(
          switchMap(() => {
            return [
              confirmEmailSuccess(),
              ...(action.successActions || []),
              addSuccessToast({
                title: 'common_success',
                content: 'auth_account_activated',
              }),
            ];
          }),
          catchError(({ error }) => [
            confirmEmailFailure({ error }),
            addErrorToast(getErrorToastBodyUtil('confirm_email_error', error)),
          ])
        );
      })
    )
  );

  signUpByAdminInvite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signUpByAdminInvite),
      switchMap(({ password, token, successActions }) => {
        const languageCode = this.storage.getItem(StorageKeys.Language, 'en');

        return this.signUpService.signUpByAdminInvite(password, languageCode, token).pipe(
          switchMap(() => [signUpByAdminInviteSuccess(), ...(successActions || [])]),
          catchError(({ error }) => of(addErrorToast(getErrorToastBodyUtil('confirm_email_error', error))))
        );
      })
    )
  );

  resendVerificationEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resendVerificationEmailAction),
      switchMap(({ email, redirectUri = '' }) => {
        const languageCode = this.storage.getItem(StorageKeys.Language, 'en');

        return this.signUpService.resendVerificationEmail(email, languageCode, redirectUri).pipe(
          switchMap(() => {
            this.store.dispatch(
              addSuccessToast({
                title: 'common_success',
                content: 'auth_resend_validation_email_success',
              })
            );
            return [];
          }),
          catchError(({ error }) =>
            of(addErrorToast(getErrorToastBodyUtil('auth_resend_validation_email_failed', error)))
          )
        );
      })
    )
  );

  constructor(
    private signUpService: SignUpService,
    private socialAuthService: SocialAuthService,
    private storage: StorageService<StorageKeys>,
    private actions$: Actions,
    private translateService: TranslateService,
    private store: Store
  ) {}
}
