import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvService, Language } from '@medrecord/core';
import { getOAuthConfigUtil } from '@medrecord/tools-utils';
import { HttpClient } from '@angular/common/http';
import { MedrecordOAuthSignInPayload, OAuthSignInPayload, OAuthSignInResponse } from '../models/interfaces';
import { AuthRouteParams, OAuthChallengeMethod, OAuthResponseType } from '../models/enums';
import { removeLastSlash } from '../utils/remove-last-slash.util';
import { RecoveryCodeResponse } from '../models/interfaces/twofa/recovery-code-response.interface';
import { TotpSecretResponse } from '../models/interfaces/twofa/totp-secret-response.interface';

@Injectable()
export class SignInService {

  constructor(private http: HttpClient, private envService: EnvService) {}

  signIn(email: string, password: string, oAuthConfig: OAuthSignInPayload): Observable<OAuthSignInResponse> {
    const body: MedrecordOAuthSignInPayload = {
      ...oAuthConfig,
      email,
      password,
    };

    return this.http.post<OAuthSignInResponse>(`${this.envService.auth}/signin/password`, body);
  }

  getTwofaQrCode(session: string): Observable<TotpSecretResponse> {
    const body = {
      session: session,
    };
    return this.http.post<TotpSecretResponse>(`${this.envService.auth}/verification/totp/init`, body);
  }

  sendTwofaSetupCode(session: string, code: string): Observable<RecoveryCodeResponse> {
    const body = {
      code: code,
      session: session,
    };
    return this.http.post<RecoveryCodeResponse>(`${this.envService.auth}/verification/totp/save`, body);
  }

  checkTwofaCode(session: string, code: string): Observable<void> {
    const body = {
      code: code,
      session: session,
    };
    return this.http.post<void>(`${this.envService.auth}/verification/totp/codecheck`, body);
  }

  reconfigTwofaInit(): Observable<TotpSecretResponse> {
    return this.http.post<TotpSecretResponse>(`${this.envService.auth}/verification/totp/change/init`, {});
  }

  reconfigTwofaInitSave(code: string): Observable<RecoveryCodeResponse> {
    const body = {
      code: code,
    };
    return this.http.post<RecoveryCodeResponse>(`${this.envService.auth}/verification/totp/change/save`, body);
  }

  recoveryTwofaCode(session: string, code: string): Observable<void> {
    const body = {
      code: code,
      session: session,
    };
    return this.http.post<void>(`${this.envService.auth}/verification/recovery/codecheck`, body);
  }

  signInWithSession(session: string, oAuthConfig: OAuthSignInPayload): Observable<OAuthSignInResponse> {
    return this.http.post<OAuthSignInResponse>(`${this.envService.auth}/signin/session`, {
      ...oAuthConfig,
      session,
    });
  }

  signInWithTempToken(tempToken: string, oAuthConfig: OAuthSignInPayload): Observable<OAuthSignInResponse> {
    return this.http.post<OAuthSignInResponse>(`${this.envService.auth}/signin/authtoken`, {
      ...oAuthConfig,
      token: tempToken,
    });
  }

  loginViaGoogle(
    idToken: string,
    languageCode: Language,
    oAuthConfig: OAuthSignInPayload
  ): Observable<OAuthSignInResponse> {
    const body = {
      state: oAuthConfig.state,
      redirect_uri: oAuthConfig.redirect_uri,
      client_id: this.envService.clientId,
      code_challenge: oAuthConfig.code_challenge,
      code_challenge_method: OAuthChallengeMethod.S256,
      response_type: OAuthResponseType.CODE,
      idToken,
      languageCode,
    };

    return this.http.post<OAuthSignInResponse>(`${this.envService.auth}/signin/googletoken`, body);
  }

  async getOAuthSignInPayload(codeVerifier: string): Promise<OAuthSignInPayload> {
    const params = new URL(document.location.href).searchParams;
    const oAuthConfig = await getOAuthConfigUtil(codeVerifier);

    return {
      client_id: params.get(AuthRouteParams.ClientId) || this.envService.clientId,
      code_challenge: params.get(AuthRouteParams.CodeChallenge) || oAuthConfig.codeChallenge,
      code_challenge_method:
        (params.get(AuthRouteParams.CodeChallengeMethod) as OAuthChallengeMethod) || OAuthChallengeMethod.S256,
      redirect_uri: removeLastSlash(params.get(AuthRouteParams.RedirectUri)) || oAuthConfig.redirectUri,
      response_type:
        ((params.get(AuthRouteParams.ResponseType) || '').toUpperCase() as OAuthResponseType) || OAuthResponseType.CODE,
      state: params.get(AuthRouteParams.State) || oAuthConfig.state,
    };
  }
}
