export enum PaletteVarNames {
  primary = '--primary-color',
  primaryLight = '--primary-light-color',
  primaryUltraLight = '--primary-ultra-light-color',
  secondary = '--secondary-color',
  link = '--link-color',
  primaryButton = '--primary-button-color',
  warning = '--warning-color',
  alert = '--alert-color',
  success = '--success-color',
  error = '--error-color',
}