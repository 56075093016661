import { Action, ActionReducer, UPDATE } from '@ngrx/store';
import { isObject, isArray } from 'lodash';

export interface LocalStorageSyncConfig<IState> {
  /**
   * @type string
   * @description Prefix for a local storage group key.
   * @default ''
   * */
  prefixStorageKey?: string;
  
  /**
   * @type string
   * @description These key is using for filling state from local storage after page reloading.
   * */
  storageKey: any;
  
  /**
   * @type string
   * @description Explain synchronizer how to define actions related to this state. For Example all actions are have same uniq prefix
   * in square braces [State Group]
   * */
  actionsWildcard: string;
  
  /**
   * @description It is used for reset state by defined action in the option payload. You can declare initialState
   * what will be set instead of current one.
   * */
  rehydrate?: {
    /**
     * @description Store will be reset when this action will be called.
     * */
    action: Action;
    
    /**
     * Will be set instead current state.
     * @default {}
     * */
    initialState?: IState;
  };
}

/**
 * @description Some features state should be cached in the local storage.
 * For proper synchronization you are able to use metaReducers.
 * This function will return configured metaReducer for sync current feature state wit a localStorage.
 *
 * __Note: That localStorage has limited size for different browser__
 * */
export const getSyncLocalStorageMetaReducer = <IState>(
  syncConfig: LocalStorageSyncConfig<IState>
) => {
  return (actionReducer: ActionReducer<IState>) => {
    return (state: IState, action: Action) => {
      const key = getKey(syncConfig.storageKey, syncConfig.prefixStorageKey);
      
      if (action.type === UPDATE) {
        state = getSyncedState<IState>(key, state);
      }
      
      if (action.type.includes(syncConfig.actionsWildcard)) {
        updateStoredStateValue(key, state);
      }
  
      if (action.type === syncConfig.rehydrate?.action?.type) {
        state = syncConfig.rehydrate.initialState || {} as IState;
        rehydrateOnAction(key);
      }
      
      return actionReducer(state, action);
    };
  };
};

export const getSyncedState = <IState>(storageKey: any, initialState: IState): IState => {
  const value = (JSON.parse(localStorage.getItem(storageKey)) || {}) as IState;
  
  let state: IState;
  if (isObject(value) && !isArray(value)) {
    state = { ...initialState, ...value} as IState;
  } else {
    state[storageKey] = value;
  }
  
  return state;
};

export const rehydrateOnAction = (storageKey: any): void => {
  localStorage.removeItem(storageKey);
};

export const updateStoredStateValue = <IState>(storageKey: any, updatedState: IState): void => {
  const value = JSON.parse(
    localStorage.getItem(storageKey)
  ) || {};
  
  localStorage.setItem(storageKey, JSON.stringify({ ...value, ...updatedState }));
};

const getKey = (storageKey: string, prefix = '') => {
  return (prefix ? prefix + '_' : '') + storageKey;
};