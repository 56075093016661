import { InjectionToken } from '@angular/core';
import { LanguagesCollection, SearchConstants } from './models/types';
import { MedrecordEnvironment } from './models/interfaces';

/**
 * URLs for connection with API
 * */
export const APP_ENVIRONMENT: InjectionToken<MedrecordEnvironment> = new InjectionToken('Environment values');

/**
 * @define Constants
 * @desc Best Practices recommend to provide all constants with Injection Tokens. Let's follow it :)
 * */

export const LANGUAGES_COLLECTION: InjectionToken<LanguagesCollection> = new InjectionToken(
  'Collection of the languages what is used inside application'
);

/**
 * @define Constants
 * @desc Best Practices recommend to provide all constants with Injection Tokens. Let's follow it :)
 * */

export const SEARCH: InjectionToken<SearchConstants> = new InjectionToken(
  'Search constants'
);

