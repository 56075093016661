import { Pipe, PipeTransform } from '@angular/core';
import { MomentLocales } from './typings';
import { MedrecordMoment } from './medrecord-moment.config';

@Pipe({
  name: 'formatDateTime'
})
export class MedrecordDatetimeFormatPipe implements PipeTransform {
  transform(date: string | Date, localeFormatKey: MomentLocales): string {
    return MedrecordMoment(date).format(localeFormatKey);
  }
}
