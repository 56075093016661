import { Inject, Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  renewTokenAction,
  renewTokenFailureAction,
  renewTokenSuccessAction,
  requestTokensAction,
  requestTokensFailureAction,
  requestTokensSuccessAction,
  requestWhoAmIAction,
  requestWhoAmIFailureAction,
  requestWhoAmISuccessAction,
  resetSignInData,
  signInFinishedRedirectAction,
} from '../auth-manager.actions';
import { Store } from '@ngrx/store';
import { TokenService } from '../../services/token.service';
import { getCodeVerifier, selectRefreshToken } from '../auth-manager.selectors';
import { addErrorToast } from '@medrecord/tools-toast';
import { getErrorToastBodyUtil } from '@medrecord/tools-utils';
import { AUTH_ROUTE_NAMES, AuthRouteNames } from '@medrecord/routes-auth';
import { Router } from '@angular/router';

@Injectable()
export class TokenEffects {
  @Effect()
  requestTokens$ = this.actions$.pipe(
    ofType(requestTokensAction),
    withLatestFrom(this.store.select(getCodeVerifier)),
    switchMap(([payload, codeVerifier]) => {
      return this.tokenService.requestTokens(payload.code, codeVerifier).pipe(
        switchMap((res) => {
          return [
            requestTokensSuccessAction(res),
            requestWhoAmIAction({
              accessToken: res.access_token,
              redirectPath: payload.redirectPath,
              redirectQueryParams: payload.redirectQueryParams,
            }),
            resetSignInData(),
          ];
        }),
        catchError(({ error }) => [
          renewTokenFailureAction({ error }),
          addErrorToast(getErrorToastBodyUtil('request_token_error', error)),
        ])
      );
    })
  );

  @Effect()
  renewToken$ = this.actions$.pipe(
    ofType(renewTokenAction),
    withLatestFrom(this.store.select(selectRefreshToken)),
    switchMap(([, refreshToken]) => {
      if (!refreshToken) {
        this.router.navigate([this.authRouteNames.Entry]);

        return [];
      }

      return this.tokenService.renewToken({ refreshToken }).pipe(
        switchMap((data) => {
          return [renewTokenSuccessAction(data)];
        }),
        catchError(({ error }) => {
          this.router.navigate([this.authRouteNames.Entry]);

          return [
            requestTokensFailureAction({ error }),
            addErrorToast(getErrorToastBodyUtil('renew_token_error', error)),
          ];
        })
      );
    })
  );

  @Effect()
  getWhoAmI$ = this.actions$.pipe(
    ofType(requestWhoAmIAction),
    switchMap(({ accessToken, redirectPath, redirectQueryParams }) =>
      this.tokenService.whoAmI(accessToken).pipe(
        switchMap((res) => {
          return [
            requestWhoAmISuccessAction({
              userId: res.id,
              email: res.email,
              roles: res.roles,
            }),
            signInFinishedRedirectAction({ path: redirectPath, queryParams: redirectQueryParams }),
          ];
        }),
        catchError(({ error }) => [
          requestWhoAmIFailureAction({ error }),
          addErrorToast(getErrorToastBodyUtil('who_am_i_error', error)),
        ])
      )
    )
  );

  constructor(
    private tokenService: TokenService,
    private actions$: Actions,
    private store: Store,
    private router: Router,

    @Inject(AUTH_ROUTE_NAMES) private authRouteNames: AuthRouteNames
  ) {}
}
