import { createAction, props } from '@ngrx/store';
import { TwofaFlow } from '../../models/enums';

export const setTwofaFlowAction = createAction(
  '[Auth Manager] Set Twofa Flow',
  props<{ flow: TwofaFlow }>()
);

export const cancelTwofaFlowAction = createAction('[Auth Manager] Cancel Twofa Flow');

export const handleTwofaNextStepAction = createAction('[Auth Manager] Handle Twofa Next Step');

export const setTwofaStepAction = createAction(
  '[Auth Manager] Set Twofa Step',
  props<{ step: number }>()
);

export const fetchTwofaQrCodeAction = createAction('[Auth Manager] Fetch Twofa Qr Code');

export const setTwofaQrCodeAction = createAction(
  '[Auth Manager] Set Twofa Qr Code',
  props<{ qrCodeUri: string }>()
);

export const submitTwofaCodeAction = createAction(
  '[Auth Manager] Submit Twofa Code',
  props<{ code: string }>()
);

export const setTwofaCodesToRememberAction = createAction(
  '[Auth Manager] Set Twofa Codes To Remember',
  props<{ codesToRemember: string[] }>()
);

export const twofaFailureAction = createAction(
  '[Auth Manager] Twofa Failure',
  props<{ error: any }>()
);

export const redirectToSettingsAction = createAction('[Auth Manager] Redirect To Settings');

export const twofaVerificationSuccess = createAction('[Auth Manager] Twofa Verification Success');
