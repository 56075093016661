/**
 * Error Code
 *
 * Error >= 10000 & < 0 server errors
 * Error >= 1000 client errors
 * */

export enum ErrorCode {
  /** Server Errors */
  PhoneAlreadySet = -1,
  EmailInUse = 10000,
  
  /** Local Errors */
  StateMissed = 1000,
}