import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService, MedrecordResponse } from '@medrecord/core';
import { Observable } from 'rxjs';
import { GrantType } from '../models/enums/grant-type.enum';
import { RenewTokenPayload, RenewTokenResponse, TokenResponse, TokenWhoAmIResponse } from '../models/interfaces';
import { TokenGetAuthResponse } from '../models/interfaces/token/token-getauth-response.interface';

@Injectable()
export class TokenService {
  constructor(private http: HttpClient, private envService: EnvService) {}

  requestTokens(code, codeVerifier): Observable<TokenResponse> {
    return this.http.post<TokenResponse>(this.envService.auth + '/token', {
      code,
      code_verifier: codeVerifier,
      client_id: this.envService.clientId,
      grant_type: GrantType.AuthorizationCode,
      redirect_uri: new URL(document.location.href).origin,
    });
  }

  renewToken(renewTokenPayload: RenewTokenPayload): Observable<RenewTokenResponse> {
    return this.http.post<RenewTokenResponse>(`${this.envService.auth}/token/renew`, renewTokenPayload);
  }

  validateToken(): Observable<MedrecordResponse> {
    return this.http.get<MedrecordResponse>(`${this.envService.auth}/token/validate`);
  }

  whoAmI(token: string): Observable<TokenWhoAmIResponse> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    return this.http.get<TokenWhoAmIResponse>(`${this.envService.auth}/token/whoami`, { headers });
  }

  getAuthToken(): Observable<TokenGetAuthResponse> {
    return this.http.get<TokenGetAuthResponse>(`${this.envService.auth}/token/getauthtoken`);
  }
}
