import { ToastModel } from '@syncfusion/ej2-angular-notifications';

export const getErrorToastBodyUtil = (
  methodId: string,
  error: { code?: number, message: string, status: string }
): ToastModel => {
  if (error?.code && error.code > 1000) {
    return {
      title: methodId,
      content: 'error_' + error.code
    };
  }
  
  return {
    title: methodId,
    content: error?.message || ''
  };
};