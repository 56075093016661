import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from './containers/spinner.component';
import { SpinnerStateModule } from './state-management/spinner-state.module';
import { provideSpinnerInterceptor } from './interceptors/spinner.interceptor';

@NgModule({
  imports: [
    CommonModule,
    SpinnerStateModule
  ],
  declarations: [
    SpinnerComponent
  ],
  providers: [
    provideSpinnerInterceptor(),
  ],
  exports: [
    SpinnerComponent
  ]
})
export class MedrecordSpinnerToolModule {
}
            