export const mapEnum = <Format = string>(enumValue: any, formatCb?: (value: string) => Format): Format[] => {
  const values: Format[] = [];
  
  formatCb = formatCb || ((value: string): any => value);
  
  for (const key in enumValue) {
    if (enumValue.hasOwnProperty(key)) {
      values.push(formatCb(enumValue[key]));
    }
  }
  
  return values;
};
