import { Injectable } from '@angular/core';
import { RGBColor, HSLColor, FullColor } from './models/interfaces';
import { ColorsCollection, Colors } from './models/types';

@Injectable({
  providedIn: 'root',
})
export class ThemingService {
  private colorsCollection: ColorsCollection = new Map<Colors, FullColor>();

  snapshot(): ColorsCollection {
    return this.colorsCollection;
  }

  getColor(colorName: Colors): FullColor {
    return this.colorsCollection.get(colorName);
  }

  saveHexColor(colorName: Colors, hex: string): void {
    if (!hex.includes('#')) {
      hex = '#000000';
    }

    const rgb = this.hexToRgb(hex);

    this.colorsCollection.set(colorName, { hex, rgb, hsl: this.rgbToHsl(rgb) });
  }

  hexToRgb(H: string): RGBColor {
    let redBite: any = 0;
    let greenBite: any = 0;
    let blueBite: any = 0;

    if (H.length === 4) {
      redBite = '0x' + H[1] + H[1];
      greenBite = '0x' + H[2] + H[2];
      blueBite = '0x' + H[3] + H[3];
    }

    if (H.length === 7) {
      redBite = '0x' + H[1] + H[2];
      greenBite = '0x' + H[3] + H[4];
      blueBite = '0x' + H[5] + H[6];
    }

    return {
      red: redBite / 255,
      green: greenBite / 255,
      blue: blueBite / 255,
    };
  }

  rgbToHex(color: RGBColor): string {
    const hex =
      '#' +
      Object.keys(color)
        .map((c) => {
          const digit = Math.round(Number.parseFloat(c) * 255).toString(16);
          return digit.length === 1 ? '0' + digit : digit;
        })
        .join('');

    if (hex.length !== 7 || !/^#[0-9A-F]{6}$/i.test(hex)) return '#000000';
    return hex;
  }

  rgbToHsl(color: RGBColor): HSLColor {
    const cMin = Math.min(color.red, color.green, color.blue);
    const cMax = Math.max(color.red, color.green, color.blue);
    const delta = cMax - cMin;

    const hue = this.getHue(color, cMax, delta);

    let lightness: number | string = (cMax + cMin) / 2;
    let saturation: number | string = delta === 0 ? 0 : delta / (1 - Math.abs(2 * lightness - 1));

    saturation = +(saturation * 100).toFixed(1) + '%';
    lightness = +(lightness * 100).toFixed(1) + '%';

    return {
      hue: hue.toString(),
      saturation: saturation.toString(),
      lightness: lightness.toString(),
    };
  }

  getPrimaryColorVariants(hex: string): string[] {
    const rgb = this.hexToRgb(hex);
    const variants = { primary: 1, primaryLight: 0.2, primaryUltraLight: 0.05 };
    const colors: string[] = [];
    Object.keys(variants).forEach((colorName) => {
      const flattenedColor = {
        red: Math.round(variants[colorName] * Math.round(rgb.red * 255) + (1 - variants[colorName]) * 255) / 255,
        green: Math.round(variants[colorName] * Math.round(rgb.green * 255) + (1 - variants[colorName]) * 255) / 255,
        blue: Math.round(variants[colorName] * Math.round(rgb.blue * 255) + (1 - variants[colorName]) * 255) / 255,
      };
      this.colorsCollection.set(colorName as Colors, {
        hex: this.rgbToHex(flattenedColor),
        rgb: flattenedColor,
        hsl: this.rgbToHsl(flattenedColor),
      });

      colors.push(colorName);
    });
    return colors;
  }

  private getHue(color: RGBColor, cMax: number, delta): number {
    let hue: any = 0;

    switch (true) {
      case delta === 0:
        hue = 0;
        break;
      case cMax === color.red:
        hue = ((color.green - color.blue) / delta) % 6;
        break;
      case cMax === color.green:
        hue = (color.blue - color.red) / delta + 2;
        break;
      default:
        hue = (color.red - color.green) / delta + 4;
    }

    hue = Math.round(hue * 60);

    return hue < 0 ? hue + 360 : hue;
  }
}
