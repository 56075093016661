import { NgModule } from '@angular/core';
import { LogoutService } from './services/logout.service';
import { AuthorizedGuard } from './guards/authorized.guard';
import { AuthManagerConstantsProviders } from './constants';

/** Services */
import { SignInService } from './services/signin.service';
import { SignUpService } from './services/signup.service';
import { PasswordService } from './services/password.service';
import { TokenService } from './services/token.service';
import { AuthRedirectService } from './services/auth-redirect.service';
import { PhoneVerificationService } from './services/phone-verification.service';
import { MedrecordOAuthService } from './services/medrecord-oauth.service';
import { AuthManagerStateModule } from './state-management/auth-manager-state.module';
import { InvitationService } from './services/invitation.service';

@NgModule({
  imports: [
    AuthManagerStateModule
  ],
  providers: [
    AuthorizedGuard,
    LogoutService,
    SignInService,
    SignUpService,
    PasswordService,
    InvitationService,
    TokenService,
    AuthRedirectService,
    PhoneVerificationService,
    MedrecordOAuthService,
    
    AuthManagerConstantsProviders.provideFormRules(),
    AuthManagerConstantsProviders.provideAccessTokenRules(),
  ]
})
export class AuthManagerModule {}
