export enum AuthRouteParams {
  RedirectUri = 'redirect_uri',
  Code = 'code',
  CodeChallenge = 'code_challenge',
  CodeChallengeMethod = 'code_challenge_method',
  ResponseType = 'response_type',
  RestoreToken = 'token',
  ClientId = 'client_id',
  Language = 'lang',
  State = 'state',
  TemporaryToken = 'temp_token',
}