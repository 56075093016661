import { Pipe, PipeTransform } from '@angular/core';
import { MedrecordMoment, MomentLocales } from '@medrecord/services-datetime';

@Pipe({
  name: 'birthate'
})
export class MedrecordBirthdayPipe implements PipeTransform {
  
  transform(date: string | Date, localeFormatKey: MomentLocales = "LL"): string {
    return MedrecordMoment(date).format(localeFormatKey);
  }
}
