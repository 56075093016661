import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvService, MedrecordResponse } from '@medrecord/core';
import { HttpClient } from '@angular/common/http';
import { ChangePasswordPayload, ForgotPasswordPayload, RestorePasswordPayload } from '../models/interfaces';

@Injectable()
export class PasswordService {
  constructor(
    private http: HttpClient,
    private envService: EnvService
  ) {}

  changePassword(changePasswordPayload: ChangePasswordPayload): Observable<MedrecordResponse> {
    return this.http.post<MedrecordResponse>(
      this.envService.auth + '/password/change',
      changePasswordPayload
    );
  }
  
  forgotPassword(forgotPasswordPayload: ForgotPasswordPayload): Observable<MedrecordResponse> {
    return this.http.post<MedrecordResponse>(
      this.envService.auth + '/password/forgot',
      forgotPasswordPayload
    );
  }
  
  restorePassword(restorePasswordPayload: RestorePasswordPayload): Observable<MedrecordResponse> {
    return this.http.post<MedrecordResponse>(
      this.envService.auth + '/password/restore',
      restorePasswordPayload
    );
  }
}