import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MedrecordMoment } from './medrecord-moment.config';
import { DatePrecision } from './typings';

@Pipe({
  name: 'precisionFormat',
})
export class PrecisionFormatPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {}
  
  transform(date: string, precision: DatePrecision): string {
    if (date && MedrecordMoment(date).isValid()) {
      switch (precision) {
        case DatePrecision.month:
          return `${this.translateService.instant('common_around')} ${MedrecordMoment(date).format('MMM YYYY')}`;
        case DatePrecision.year:
          return `${this.translateService.instant('common_around')} ${MedrecordMoment(date).format('YYYY')}`;
        case DatePrecision.day:
          return MedrecordMoment(date).format('LL');
        case DatePrecision.second:
          return MedrecordMoment(date).format('LT');
        default:
          return MedrecordMoment(date).format('LLL');
      }
    }
    return '';
  }
}
