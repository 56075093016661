import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  backToLogin,
  checkPhoneCode,
  checkPhoneCodeFailure,
  checkPhoneCodeSuccess,
  getPhoneCode,
  getPhoneCodeFailure,
  getPhoneCodeSuccess,
  initPhone,
  initPhoneChange,
  initPhoneChangeFailure,
  initPhoneChangeSuccess,
  initPhoneFailure,
  initPhoneSuccess,
  navigateToSavePhonePage,
  phoneChangeSave,
  phoneChangeSaveFailure,
  phoneChangeSaveSuccess,
  savePhone,
  savePhoneFailure,
  savePhoneSuccess
} from '../auth-manager.actions';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import { PhoneVerificationService } from '../../services/phone-verification.service';
import { Store } from '@ngrx/store';
import { selectPhone, selectSession } from '../auth-manager.selectors';
import { addErrorToast } from '@medrecord/tools-toast';
import { getErrorToastBodyUtil } from '@medrecord/tools-utils';

@Injectable()
export class PhoneVerificationEffects {
  @Effect()
  initPhoneChange$ = this.actions$.pipe(
    ofType(initPhoneChange),
    withLatestFrom(this.store.select(selectSession)),
    switchMap(([payload, session]) => {
      if (!session) {
        return [
          backToLogin()
        ];
      }
      
      return this.phoneVerificationService
        .initPhoneChange({ session, phone: payload.phone })
        .pipe(
          switchMap((res) => {
            return [
              initPhoneChangeSuccess(res)
            ];
          }),
          catchError(({error}) => [
            initPhoneChangeFailure({ error }),
            addErrorToast(
              getErrorToastBodyUtil('init_phone_change_error', error)
            )
          ])
        );
    })
  );
  
  @Effect()
  savePhoneChange$ = this.actions$.pipe(
    ofType(phoneChangeSave),
    withLatestFrom(this.store.select(selectSession)),
    switchMap(([payload, session]) => {
      const { phone, code } = payload;
  
      if (!session || !phone) {
        return [
          backToLogin()
        ];
      }
      
      return this.phoneVerificationService
        .savePhoneChange({ session, phone, code })
        .pipe(
          switchMap((response) => [
            phoneChangeSaveSuccess(response)
          ]),
          catchError(({error}) => [
            phoneChangeSaveFailure({ error }),
            addErrorToast(
              getErrorToastBodyUtil('save_phone_change_error', error)
            )
          ])
        );
    })
  );
  @Effect()
  initPhone$ = this.actions$.pipe(
    ofType(initPhone),
    withLatestFrom(this.store.select(selectSession)),
    switchMap(([payload, session]) => {
      
      if (!session) {
        return [
          backToLogin()
        ];
      }
      
      return this.phoneVerificationService
        .initPhone({ session, phone: payload.phone })
        .pipe(
          switchMap((response) => {
            return [
              initPhoneSuccess(response),
              navigateToSavePhonePage()
            ];
          }),
          catchError(({ error }) => [
            initPhoneFailure({ error }),
            addErrorToast(
              getErrorToastBodyUtil('init_phone_error', error)
            )
          ])
        );
    })
  );
  
  @Effect()
  savePhone$ = this.actions$.pipe(
    ofType(savePhone),
    withLatestFrom(
      this.store.select(selectPhone),
      this.store.select(selectSession),
    ),
    switchMap(([payload, phone, session]) => {
      return this.phoneVerificationService
        .savePhone({ session, code: payload.code, phone })
        .pipe(
          switchMap((response) => [
            savePhoneSuccess(response)
          ]),
          catchError(({error}) => [
            savePhoneFailure({ error }),
            addErrorToast(
              getErrorToastBodyUtil('save_phone_error', error)
            )
          ])
        );
    })
  );
  
  @Effect()
  getPhoneCode$ = this.actions$.pipe(
    ofType(getPhoneCode),
    withLatestFrom(this.store.select(selectSession)),
    switchMap(([, session]) => {
      if (!session) {
        return [
          backToLogin()
        ];
      }
      
      return this.phoneVerificationService
        .requestCode({ session })
        .pipe(
          switchMap((response) => [
            getPhoneCodeSuccess(response)
          ]),
          catchError(({error}) => [
            getPhoneCodeFailure({ error }),
            addErrorToast(
              getErrorToastBodyUtil('get_phone_code_error', error)
            )
          ])
        );
    })
  );
  
  @Effect()
  checkPhoneCode$ = this.actions$.pipe(
    ofType(checkPhoneCode),
    withLatestFrom(this.store.select(selectSession)),
    switchMap(([payload, session]) => {
      return this.phoneVerificationService
        .checkCode({ session, code: payload.code })
        .pipe(
          switchMap((response) => [
            checkPhoneCodeSuccess(response)
          ]),
          catchError(({error}) => [
            checkPhoneCodeFailure({ error }),
            addErrorToast(
              getErrorToastBodyUtil('check_phone_code_error', error)
            )
          ])
        );
    })
  );
  
  constructor(
    private actions$: Actions,
    private store: Store,
    private phoneVerificationService: PhoneVerificationService
  ) {}
}