import { createAction, props,  } from '@ngrx/store';
import {
  ChangePasswordPayload,
  ForgotPasswordPayload,
  OAuthSignInResponse,
  RestorePasswordPayload
} from '../../models/interfaces';
import { WithSuccessActions } from '@medrecord/core';

/** Change Password */
export const changePasswordAction = createAction(
  '[Auth Manager] Change Password',
  props<ChangePasswordPayload>()
);

export const changePasswordSuccessAction = createAction(
  '[Auth Manager] Change Password Success',
  props<OAuthSignInResponse>()
);

export const changePasswordFailureAction = createAction(
  '[Auth Manager] Change Password Failure',
  props<{ error: any }>()
);

/** Forgot Password */
export const forgotPasswordAction = createAction(
  '[Auth Manager] Forgot Password',
  props<WithSuccessActions<ForgotPasswordPayload>>()
);

export const forgotPasswordSuccessAction = createAction(
  '[Auth Manager] Forgot Password Success',
  props<{ config: { email: string, time: string } }>()
);

export const forgotPasswordFailureAction = createAction(
  '[Auth Manager] Forgot Password Failure',
  props<{ error: any }>()
);

export const removePassedDelayAction = createAction(
  '[Auth Manager] Remove Passed Delay',
  props<{ email: string }>()
);


/** Restore Password */
export const restorePasswordAction = createAction(
  '[Auth Manager] Restore Password',
  props<WithSuccessActions<RestorePasswordPayload>>()
);

export const restorePasswordSuccessAction = createAction(
  '[Auth Manager] Restore Password Success'
);

export const restorePasswordFailureAction = createAction(
  '[Auth Manager] Restore Password Failure',
  props<{ error: any }>()
);
