import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvService } from '@medrecord/core';
import { CustomerMetadata } from './interfaces';

export interface CustomerMetadataResponse {
  data: CustomerMetadata;
}

@Injectable({
  providedIn: 'root',
})
export class CustomerMetadataService {
  constructor(
    private http: HttpClient,
    private envService: EnvService
  ) {
  }
  
  fetchMetadata(): Observable<CustomerMetadataResponse> {
    return this.http.get<CustomerMetadataResponse>(`${this.envService.middlewareBackend}/customer-metadata`);
  }
}
