export enum ObservationType {
  activeMinutes = 'ACTIVE_MINUTES',
  alcoholUse = 'ALCOHOL_USE',
  attention = 'ATTENTION',
  balance = 'BALANCE',
  basalMetabolicRate = 'BASAL_METABOLIC_RATE',
  bloodGlucose = 'BLOOD_GLUCOSE',
  bloodPressure = 'BLOOD_PRESSURE',
  bodyFatPercentage = 'BODY_FAT_PERCENTAGE',
  bodyHeight = 'BODY_HEIGHT',
  bodyMassIndex = 'BODY_MASS_INDEX',
  bodyTemperature = 'BODY_TEMPERATURE',
  bodyWeight = 'BODY_WEIGHT',
  cPeptide = 'C_PEPTIDE',
  cognitiveMotor = 'COGNITIVE_MOTOR',
  drugUse = 'DRUG_USE',
  ecg = 'ECG',
  episodicMemory = 'EPISODIC_MEMORY',
  executiveFunction = 'EXECUTIVE_FUNCTION',
  functionalOrMentalStatus = 'FUNCTIONAL_OR_MENTAL_STATUS',
  gaitSpeed = 'GAIT_SPEED',
  generic = 'GENERIC',
  gpJournalEntry = 'GP_JOURNAL_ENTRY',
  hba1c = 'HBA1C',
  heartMinutes = 'HEART_MINUTES',
  heartRate = 'HEART_RATE',
  hemoglobin = 'HEMOGLOBIN',
  laboratoryTestResult = 'LABORATORY_TEST_RESULT',
  livingSituation = 'LIVING_SITUATION',
  longTermStress = 'LONG_TERM_STRESS',
  oxygenSaturation = 'OXYGEN_SATURATION',
  processingSpeed = 'PROCESSING_SPEED',
  pulseRate = 'PULSE_RATE',
  quantity = 'QUANTITY',
  respiratoryRate = 'RESPIRATORY_RATE',
  shortTermStress = 'SHORT_TERM_STRESS',
  sixMinutesWalking = 'SIX_MINUTES_WALKING',
  sleeping = 'SLEEPING',
  stepCounter = 'STEP_COUNTER',
  timedUpAndGo = 'TIMED_UP_AND_GO',
  tobaccoUse = 'TOBACCO_USE',
  waistCircumference = 'WAIST_CIRCUMFERENCE',
  waistHeightRatio = 'WAIST_HEIGHT_RATIO',
  workingMemory = 'WORKING_MEMORY',
  waterIntake = 'WATER_INTAKE',
}
