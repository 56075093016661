import { APP_INITIALIZER, Provider } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '@medrecord/tools-storage';
import { StorageKeys, Language } from '../models/enums';
import { MedrecordMoment } from '@medrecord/services-datetime';
import { EnvService } from '../services/env.service';

export function controlDefaultLanguage(
  service: TranslateService,
  storage: StorageService<StorageKeys>,
  envService: EnvService
): Function {
  return async function (): Promise<void> {
    const url = new URL(location.href);
    let storedLanguage: Language = storage.getItem(StorageKeys.Language);

    if (
      url.searchParams.get('lang') &&
      (Object as any).keys(Language).includes(url.searchParams.get('lang')?.toUpperCase())
    ) {
      storedLanguage = url.searchParams.get('lang') as Language;
      storage.setItem(StorageKeys.Language, storedLanguage);
    }

    if (!storedLanguage) {
      const languageKey = MedrecordMoment.locale().toUpperCase();

      const browserLanguage = (Object as any)
        .keys(Language)
        .includes(navigator.language?.split('-')?.[0]?.toUpperCase())
        ? Language[navigator.language?.split('-')?.[0]?.toUpperCase()]
        : null;

      storedLanguage =
        browserLanguage || (Object as any).keys(Language).includes(envService.defaultLanguage?.toUpperCase())
          ? Language[envService.defaultLanguage?.toUpperCase()]
          : Language[languageKey];

      if (!envService.enableGermanLanguageAuth && storedLanguage === Language.DE)
        storedLanguage = Language[envService.defaultLanguage?.toUpperCase()];
      storage.setItem(StorageKeys.Language, storedLanguage);
    }

    await service.use(storedLanguage).toPromise();
  };
}

export function provideDefaultLang(): Provider {
  return {
    provide: APP_INITIALIZER,
    useFactory: controlDefaultLanguage,
    multi: true,
    deps: [TranslateService, StorageService, EnvService],
  };
}
