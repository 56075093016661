import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvService, MedrecordResponse } from '@medrecord/core';
import { HttpClient } from '@angular/common/http';
import { RestorePasswordPayload } from '../models/interfaces';

@Injectable()
export class InvitationService {
  constructor(
    private http: HttpClient,
    private envService: EnvService
  ) {
  }
  
  acceptInvitation(setPasswordPayload: RestorePasswordPayload): Observable<MedrecordResponse> {
    return this.http.post<MedrecordResponse>(
      this.envService.auth + '/api/users/invitation/accept',
      setPasswordPayload
    );
  }
}