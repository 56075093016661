export enum HttpStatusCode {
  Continue = "100 CONTINUE",
  SwitchingProtocols = "101 SWITCHING_PROTOCOLS",
  Processing = "102 PROCESSING",
  Checkpoint = "103 CHECKPOINT",
  Ok = "200 OK",
  Created = "201 CREATED",
  Accepted = "202 ACCEPTED",
  NonAuthoritativeInformation = "203 NON_AUTHORITATIVE_INFORMATION",
  NoContent = "204 NO_CONTENT",
  ResetContent = "205 RESET_CONTENT",
  PartialContent = "206 PARTIAL_CONTENT",
  MultiStatus = "207 MULTI_STATUS",
  AlreadyReported = "208 ALREADY_REPORTED",
  ImUsed = "226 IM_USED",
  MultipleChoices = "300 MULTIPLE_CHOICES",
  MovedPermanently = "301 MOVED_PERMANENTLY",
  Found = "302 FOUND",
  MovedTemporarily = "302 MOVED_TEMPORARILY",
  SeeOther = "303 SEE_OTHER",
  NotModified = "304 NOT_MODIFIED",
  UseProxy = "305 USE_PROXY",
  TemporaryRedirect = "307 TEMPORARY_REDIRECT",
  PermanentRedirect = "308 PERMANENT_REDIRECT",
  BadRequest = "400 BAD_REQUEST",
  Unauthorized = "401 UNAUTHORIZED",
  PaymentRequired = "402 PAYMENT_REQUIRED",
  Forbidden = "403 FORBIDDEN",
  NotFound = "404 NOT_FOUND",
  MethodNotAllowed = "405 METHOD_NOT_ALLOWED",
  NotAcceptable = "406 NOT_ACCEPTABLE",
  ProxyAuthenticationRequired = "407 PROXY_AUTHENTICATION_REQUIRED",
  RequestTimeout = "408 REQUEST_TIMEOUT",
  Conflict = "409 CONFLICT",
  Gone = "410 GONE",
  LengthRequired = "411 LENGTH_REQUIRED",
  PreconditionFailed = "412 PRECONDITION_FAILED",
  PayloadTooLarge = "413 PAYLOAD_TOO_LARGE",
  RequestEntityTooLarge = "413 REQUEST_ENTITY_TOO_LARGE",
  UriTooLong = "414 URI_TOO_LONG",
  RequestUriTooLong = "414 REQUEST_URI_TOO_LONG",
  UnsupportedMediaType = "415 UNSUPPORTED_MEDIA_TYPE",
  RequestedRangeNotSatisfiable = "416 REQUESTED_RANGE_NOT_SATISFIABLE",
  ExpectationFailed = "417 EXPECTATION_FAILED",
  IAmATeapot = "418 I_AM_A_TEAPOT",
  InsufficientSpaceOnResource = "419 INSUFFICIENT_SPACE_ON_RESOURCE",
  MethodFailure = "420 METHOD_FAILURE",
  DestinationLocked = "421 DESTINATION_LOCKED",
  UnprocessableEntity = "422 UNPROCESSABLE_ENTITY",
  Locked = "423 LOCKED",
  FailedDependency = "424 FAILED_DEPENDENCY",
  UpgradeRequired = "426 UPGRADE_REQUIRED",
  PreconditionRequired = "428 PRECONDITION_REQUIRED",
  TooManyRequests = "429 TOO_MANY_REQUESTS",
  RequestHeaderFieldsTooLarge = "431 REQUEST_HEADER_FIELDS_TOO_LARGE",
  UnavailableForLegalReasons = "451 UNAVAILABLE_FOR_LEGAL_REASONS",
  InternalServerError = "500 INTERNAL_SERVER_ERROR",
  NotImplemented = "501 NOT_IMPLEMENTED",
  BadGateway = "502 BAD_GATEWAY",
  ServiceUnavailable = "503 SERVICE_UNAVAILABLE",
  GatewayTimeout = "504 GATEWAY_TIMEOUT",
  HttpVersionNotSupported = "505 HTTP_VERSION_NOT_SUPPORTED",
  VariantAlsoNegotiates = "506 VARIANT_ALSO_NEGOTIATES",
  InsufficientStorage = "507 INSUFFICIENT_STORAGE",
  LoopDetected = "508 LOOP_DETECTED",
  BandwidthLimitExceeded = "509 BANDWIDTH_LIMIT_EXCEEDED",
  NotExtended = "510 NOT_EXTENDED",
  NetworkAuthenticationRequired = "511 NETWORK_AUTHENTICATION_REQUIRED"
}